import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { PATTERN_NAME } from '../../utils/constants';
import styles from './EditUserInfo.module.css';
import editAvatar from '../../images/editAvatar.svg';

const EditUserInfo: FC = (): React.ReactElement => {
  const {
    register,
    // formState: { errors },
    // watch,
  } = useForm({
    mode: 'onChange',
  });

  // setNewPassword(watch('firstName'), watch('lastName'))
  // const firstNameError: string | undefined = errors?.firstName?.message?.toString();
  // const lastNameError = errors?.lastName?.message?.toString();

  return (
    <form className={styles.form}>
      <div className={styles.formContainer}>
        <img src={editAvatar} alt="Аватар пользователя" className={styles.editAvatar} />
        <label className={styles.label} htmlFor="text">
          Имя
        </label>
        <input
          id="first-name"
          className={styles.input}
          type="text"
          {...register('firstName', {
            required: 'Поле обязательно к заполнению',
            minLength: {
              value: 2,
              message: 'Минимум 2 символа',
            },
            maxLength: {
              value: 30,
              message: 'Максимум 30 символов',
            },
            pattern: {
              value: PATTERN_NAME,
              message: 'Введены недопустимые символы',
            },
          })}
        />
        <span className={styles.spanError}>{}</span>
        <label className={styles.label} htmlFor="text">
          Фамилия
        </label>
        <input
          id="last-name"
          className={styles.input}
          type="text"
          {...register('lastName', {
            required: 'Поле обязательно к заполнению',
            minLength: {
              value: 2,
              message: 'Минимум 2 символа',
            },
            maxLength: {
              value: 30,
              message: 'Максимум 30 символов',
            },
            pattern: {
              value: PATTERN_NAME,
              message: 'Введены недопустимые символы',
            },
          })}
        />
        <span className={styles.spanError}>{}</span>
      </div>
      <div className={styles.btnContainer}>
        <button type="submit" className={styles.btn}>
          Сохранить
        </button>
      </div>
    </form>
  );
};

export default EditUserInfo;
