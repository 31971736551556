import { combineReducers } from 'redux';
import getUserCredentialsReducer from './userReducers';
import checkPersonResponce from './checkPersonReducer';
import getReportHistory from './reportHistoryReducers';

const rootReducer = combineReducers({
  currentUser: getUserCredentialsReducer,
  checkPerson: checkPersonResponce,
  reportHistory: getReportHistory,
});

export default rootReducer;
