import { ICheckPersonResponse } from './check_person_interface';

const baseURL = 'https://individuals.wownage.com';
const baseHeaders = {
  'Content-Type': 'application/json',
};

const checkResponse = (res: Response) => {
  if (res.ok) return res.json();
  return res.json().then((err) => Promise.reject(err));
};

interface IcheckResponse {
  success: boolean;
}

type TcheckSuccess<T> = {
  success: boolean;
  json: () => Promise<any>;
} & T;

const checkSuccess = <T>(res: TcheckSuccess<T>): Promise<TcheckSuccess<T>> => {
  if (res) return Promise.resolve(res);
  return Promise.reject(res);
};

const request = <T>(
  endpoint: string,
  method: string,
  headers?: { [key: string]: string },
  body?: string
): Promise<T> => {
  return fetch(`${baseURL}${endpoint}`, {
    method,
    headers,
    body,
    credentials: 'include',
  })
    .then((res) => checkResponse(res))
    .then((res) => checkSuccess(res));
};

interface IRegisterUser extends IcheckResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  countFreeReport: number;
  balance: number;
  role: string;
  registered: string;
}

interface IApiCheck {
  fsspOk: boolean;
  innOk: boolean;
  passportOk: boolean;
  gibddOk: boolean;
  rosFinMonOk: boolean;
  selfEmplOk: boolean;
  bankruptOk: boolean;
}

export const registerUser = (
  firstName: string,
  lastName: string,
  email: string,
  password: string
): Promise<IRegisterUser> =>
  request<IRegisterUser>(
    '/auth/sign-up',
    'POST',
    baseHeaders,
    JSON.stringify({ firstName, lastName, email, password })
  ).then((res: IRegisterUser) => {
    return res;
  });

export const loginUser = (email: string, password: string): Promise<IRegisterUser> =>
  request<IRegisterUser>(
    '/auth/sign-in',
    'POST',
    baseHeaders,
    JSON.stringify({ email, password })
  ).then((res: IRegisterUser) => {
    return res;
  });

export const authCheck = (): Promise<IRegisterUser> =>
  request<IRegisterUser>('/auth/check', 'GET', baseHeaders).then((res: IRegisterUser) => {
    return res;
  });

export const apiCheck = (): Promise<IApiCheck> =>
  request<IApiCheck>('/api/performance', 'GET', baseHeaders).then((res: IApiCheck) => {
    return res;
  });

export const confirmEmail = (email: string, code: string): Promise<IRegisterUser> =>
  request<IRegisterUser>(
    '/auth/confirm-email',
    'POST',
    baseHeaders,
    JSON.stringify({ email, code })
  ).then((res: IRegisterUser) => {
    return res;
  });

interface IResponceWithMessageOnly extends IcheckResponse {
  message: string;
}

export const resendConfirmEmailCode = (email: string): Promise<IResponceWithMessageOnly> =>
  request<IResponceWithMessageOnly>(
    `/auth/resend-confirmation-code?email=${email}`,
    'POST',
    baseHeaders
  ).then((res: IResponceWithMessageOnly) => {
    return res;
  });

export const resetPassword = (email: string): Promise<IResponceWithMessageOnly> =>
  request<IResponceWithMessageOnly>(
    `/auth/reset-password?email=${email}`,
    'POST',
    baseHeaders
  ).then((res: IResponceWithMessageOnly) => {
    return res;
  });

export const confirmResetPassword = (
  email: string,
  code: number
): Promise<IResponceWithMessageOnly> =>
  request<IResponceWithMessageOnly>(
    '/auth/confirm-reset-password',
    'POST',
    baseHeaders,
    JSON.stringify({ email, code })
  ).then((res: IResponceWithMessageOnly) => {
    return res;
  });

export const resendResetPasswordCode = (email: string): Promise<IResponceWithMessageOnly> =>
  request<IResponceWithMessageOnly>(
    `/auth/resend-reset-password-code?email=${email}`,
    'POST',
    baseHeaders
  ).then((res: IResponceWithMessageOnly) => {
    return res;
  });

export const setNewPassword = (
  email: string,
  password: string
): Promise<IResponceWithMessageOnly> =>
  request<IResponceWithMessageOnly>(
    '/auth/set-new-password',
    'POST',
    baseHeaders,
    JSON.stringify({ email, password })
  ).then((res: IResponceWithMessageOnly) => {
    return res;
  });

export const updateUserCredentials = (
  email: string,
  firstName: string,
  lastName: string,
  password: string | undefined
): Promise<IRegisterUser> =>
  request<IRegisterUser>(
    '/user/profile/update-user',
    'PATCH',
    baseHeaders,
    JSON.stringify({ email, firstName, lastName, password })
  ).then((res: IRegisterUser) => {
    return res;
  });

export const signOut = (): Promise<IResponceWithMessageOnly> =>
  request<IResponceWithMessageOnly>('/auth/sign-out', 'POST', baseHeaders).then(
    (res: IResponceWithMessageOnly) => {
      return res;
    }
  );

interface IGetUserCheck extends ICheckPersonResponse, IcheckResponse {}

export const getPersonCheck = (
  lastName: string,
  firstName: string,
  fatherName: string,
  passport: string,
  drivingLicence: string,
  dateOfBirth: string,
  dateOfLicence: string,
  // eslint-disable-next-line no-unused-vars
  userId: number
): Promise<IGetUserCheck> => {
  if (drivingLicence === '') {
    return request<IGetUserCheck>(
      `/api/report?userId=${userId}`,
      // `/api/test/report?version=2`,
      'POST',
      baseHeaders,
      JSON.stringify({
        lastName,
        firstName,
        fatherName,
        passport,
        dateOfBirth,
      })
    ).then((res: IGetUserCheck) => {
      return res;
    });
  }
  return request<IGetUserCheck>(
    `/api/report?userId=${userId}`,
    // `/api/test/report?version=0`,
    'POST',
    baseHeaders,
    JSON.stringify({
      lastName,
      firstName,
      fatherName,
      passport,
      drivingLicence,
      dateOfBirth,
      dateOfLicence,
    })
  ).then((res: IGetUserCheck) => {
    return res;
  });
};

export interface IGetUserReportHistory extends IcheckResponse {
  [index: number]: ICheckPersonResponse;
}

export const getUserReportHistory = (
  userId: number,
  pageNumber: number,
  pageSize: number
): Promise<IGetUserReportHistory> =>
  request<IGetUserReportHistory>(
    `/user/profile/report/history?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    'GET',
    baseHeaders
  ).then((res: IGetUserReportHistory) => {
    return res;
  });

export const getUserReportHistoryByName = (
  userId: number,
  pageNumber: number,
  pageSize: number,
  name: string
): Promise<IGetUserReportHistory> =>
  request<IGetUserReportHistory>(
    `/user/profile/report/history?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}&lastName=${name}`,
    'GET',
    baseHeaders
  ).then((res: IGetUserReportHistory) => {
    return res;
  });
