// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailConfirmationPopup_confirmationText__RibwD {
  font-size: 18px;
  font-weight: 400;
}

.EmailConfirmationPopup_input__G4gAa {
  margin: 0 0 0;
  padding: 0 8px 0 16px;
  min-width: 160px;
  border-radius: 8px;
  background-color: #f6f6f6;
  height: 48px;
  border: none;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  caret-color: #618DFF;
}

.EmailConfirmationPopup_inputError__2khLJ {
  display: block;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 10px;
  color: red;
  margin: 10px 0 10px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/EmailConfirmationPopup/EmailConfirmationPopup.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,UAAU;EACV,uBAAuB;AACzB","sourcesContent":[".confirmationText {\n  font-size: 18px;\n  font-weight: 400;\n}\n\n.input {\n  margin: 0 0 0;\n  padding: 0 8px 0 16px;\n  min-width: 160px;\n  border-radius: 8px;\n  background-color: #f6f6f6;\n  height: 48px;\n  border: none;\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 20px;\n  caret-color: #618DFF;\n}\n\n.inputError {\n  display: block;\n  height: 14px;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 10px;\n  color: red;\n  margin: 10px 0 10px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationText": `EmailConfirmationPopup_confirmationText__RibwD`,
	"input": `EmailConfirmationPopup_input__G4gAa`,
	"inputError": `EmailConfirmationPopup_inputError__2khLJ`
};
export default ___CSS_LOADER_EXPORT___;
