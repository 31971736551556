/* eslint-disable react/jsx-indent-props */
import React, { FC, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import '../../vendor/normalize.css';
import '../../vendor/fonts.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MainPage from '../../pages/MainPage/MainPage';
import SignInPopup from '../SignInPopup/SignInPopup';
import SignUpPopup from '../SignUpPopup/SignUpPopup';
import EmailConfirmationPopup from '../EmailConfirmationPopup/EmailConfirmationPopup';
import UserProfilePopup from '../UserProfilePopup/userProfilePopup';
import ReportPage from '../../pages/ReportPage/ReportPage';
import AuthorisedUserPage from '../../pages/AuthorisedUserPage/AuthorisedUserPage';
import Admin from '../../pages/Admin/Admin';
import EditProfile from '../../pages/EditProfile/EditProfile';
import ReportHistory from '../../pages/ReportHistory/ReportHistory';
import { useDispatch } from '../../services/hooks/reduxHooks';
import { updateUser } from '../../services/actions/userActions';
import { authCheck } from '../../utils/Api';
import Preloader from '../Preloader/Preloader';
import InfoPopup from '../InfoPopup/InfoPopup';
import './App.css';
import Button from '../Button/Button';

import ProtectedRouteElement from '../ProtectedRouteElement/ProtectedRouteElement';

const App: FC = (): React.ReactElement => {
  const dispatch = useDispatch();

  const closeAllPopups = () => {
    setIsSignInPopupOpened(false);
    setIsSignUpPopupOpened(false);
    setIsEmailConfirmationPopupOpened(false);
    setIsInfoPopupOpened(false);
    setIsUserProfilePopupOpened(false);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const onSignInPopup = () => {
    setIsSignInPopupOpened(true);
  };

  const onSignUpPopup = () => {
    setIsSignUpPopupOpened(true);
  };

  const onEmailConfirmationPopup = () => {
    setIsEmailConfirmationPopupOpened(true);
  };

  const onUserProfilePopup = () => {
    setIsUserProfilePopupOpened(true);
  };

  const onPersonReport = () => {
    setIsReportAgreed(true);
    closeAllPopups();
  };

  const cancelPersonReport = () => {
    setIsReportAgreed(false);
    closeAllPopups();
  };

  const [isSignInPopupOpened, setIsSignInPopupOpened] = React.useState(false);
  const [isSignUpPopupOpened, setIsSignUpPopupOpened] = React.useState(false);
  const [isEmailConfirmationPopupOpened, setIsEmailConfirmationPopupOpened] = React.useState(false);
  const [isUserProfilePopupOpened, setIsUserProfilePopupOpened] = React.useState(false);
  const [isReportAgreed, setIsReportAgreed] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState('');
  const [personRequestInfo, setPersonRequestInfo] = React.useState({
    name: '',
    lastName: '',
    fatherName: '',
    passport: '',
    drivingLicence: '',
    dateOfBirth: '',
    dateOfLicence: '',
  });
  const [isPreloaderShown, setIsPreloaderShown] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isInfoPopupOpened, setIsInfoPopupOpened] = React.useState(false);
  const [infoText, setInfoText] = React.useState('');

  useEffect(() => {
    dispatch(updateUser());
  }, [isLoggedIn]);

  useEffect(() => {
    authCheck()
      .then(() => {
        setIsLoggedIn(true);
        navigate(location.pathname);
      })
      .catch((err) => {
        setIsLoggedIn(false);
        navigate('/');
        console.error(err);
      });
  }, []);

  return (
    <div className="app">
      {isPreloaderShown && <Preloader />}
      <InfoPopup isInfoPopupOpened={isInfoPopupOpened} infoText={infoText} onClose={closeAllPopups}>
        <Button
          type="button"
          btnText="Закрыть"
          isBtnBlue
          isBtnDisabled={false}
          isButtonReport
          onClick={closeAllPopups}
        />
      </InfoPopup>
      <UserProfilePopup
        onClose={closeAllPopups}
        isPopupOpened={isUserProfilePopupOpened}
        isLoggedIn={setIsLoggedIn}
        isInfoPopupOpened={setIsInfoPopupOpened}
        infoText={setInfoText}
      />
      <Header
        isSignInPopupOpened={onSignInPopup}
        isSignUpPopupOpened={onSignUpPopup}
        isUserProfilePopupOpened={onUserProfilePopup}
      />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <SignUpPopup
                onClose={closeAllPopups}
                isPopupOpened={isSignUpPopupOpened}
                openSignInPopup={onSignInPopup}
                openSignUpPopup={onSignUpPopup}
                openEmailConfirmationPopup={onEmailConfirmationPopup}
                userEmail={setUserEmail}
                isPreloaderShown={setIsPreloaderShown}
                isInfoPopupOpened={setIsInfoPopupOpened}
                infoText={setInfoText}
              />
              <SignInPopup
                onClose={closeAllPopups}
                isPopupOpened={isSignInPopupOpened}
                openSignInPopup={onSignInPopup}
                openSignUpPopup={onSignUpPopup}
                isPreloaderShown={setIsPreloaderShown}
                isLoggedIn={setIsLoggedIn}
                isInfoPopupOpened={setIsInfoPopupOpened}
                infoText={setInfoText}
              />
              <EmailConfirmationPopup
                onClose={closeAllPopups}
                isPopupOpened={isEmailConfirmationPopupOpened}
                openSignInPopup={onSignInPopup}
                openSignUpPopup={onSignUpPopup}
                userEmail={userEmail}
                isLoggedIn={setIsLoggedIn}
                isPreloaderShown={setIsPreloaderShown}
                isInfoPopupOpened={setIsInfoPopupOpened}
                infoText={setInfoText}
              />
              {/* <UserProfilePopup
                onClose={closeAllPopups}
                isPopupOpened={isUserProfilePopupOpened}
                isLoggedIn={setIsLoggedIn}
                isInfoPopupOpened={setIsInfoPopupOpened}
                infoText={setInfoText}
              /> */}
              <MainPage isSignInPopupOpened={onSignInPopup} />
            </>
          }
        />
        <Route
          path="/report"
          element={
            <>
              {/* <UserProfilePopup
                onClose={closeAllPopups}
                isPopupOpened={isUserProfilePopupOpened}
                isLoggedIn={setIsLoggedIn}
                isInfoPopupOpened={setIsInfoPopupOpened}
                infoText={setInfoText}
              /> */}
              <ReportPage personRequestInfo={personRequestInfo} />
            </>
          }
        />
        <Route
          path="/registereduser"
          element={
            <>
              <ProtectedRouteElement
                isLoggedIn={isLoggedIn}
                element={
                  <AuthorisedUserPage
                    personRequestInfo={setPersonRequestInfo}
                    isPreloaderShown={setIsPreloaderShown}
                    isInfoPopupOpened={setIsInfoPopupOpened}
                    infoText={setInfoText}
                    isReportAgreed={isReportAgreed}
                    onReportAgreed={setIsReportAgreed}
                  />
                }
              />
              <InfoPopup
                isInfoPopupOpened={isInfoPopupOpened}
                infoText={infoText}
                onClose={closeAllPopups}
              >
                <div className="btnBlock">
                  <Button
                    type="button"
                    btnText="Получить отчет"
                    isBtnBlue
                    isBtnDisabled={false}
                    isButtonReport
                    onClick={onPersonReport}
                  />
                  <Button
                    type="button"
                    btnText="Попробую позже"
                    isButtonReport
                    isBtnBlue={false}
                    isBtnDisabled={false}
                    onClick={cancelPersonReport}
                    isButtonLarge={false}
                  />
                </div>
              </InfoPopup>
              {/* <UserProfilePopup
                onClose={closeAllPopups}
                isPopupOpened={isUserProfilePopupOpened}
                isLoggedIn={setIsLoggedIn}
                isInfoPopupOpened={setIsInfoPopupOpened}
                infoText={setInfoText}
              /> */}
            </>
          }
        />
        <Route path="/admin" element={<Admin />} />
        <Route
          path="/edit-password"
          element={
            <>
              <EditProfile />
              {/* <UserProfilePopup
                onClose={closeAllPopups}
                isPopupOpened={isUserProfilePopupOpened}
                isLoggedIn={setIsLoggedIn}
                isInfoPopupOpened={setIsInfoPopupOpened}
                infoText={setInfoText}
              /> */}
            </>
          }
        />
        <Route
          path="/report-history"
          element={
            <>
              <ReportHistory />
              {/* <UserProfilePopup
                onClose={closeAllPopups}
                isPopupOpened={isUserProfilePopupOpened}
                isLoggedIn={setIsLoggedIn}
                isInfoPopupOpened={setIsInfoPopupOpened}
                infoText={setInfoText}
              /> */}
            </>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
