import {
  GET_USER_CREDENTIALS,
  GET_USER_CREDENTIALS_SUCCESS,
  GET_USER_CREDENTIALS_FAILURE,
  USER_LOGOUT,
  USER_RESET_FAILURE,
} from '../../utils/constants';
import { TApplicationActions } from '../actions/types';

export interface IUserState {
  id: number | null;
  firstName: string;
  lastName: string;
  email: string;
  countFreeReport: number | null;
  balance: number | null;
  role: string;
  registered: string;
  getUserProcessing: boolean;
  getUserFailure: boolean;
  showFailureMessage: boolean;
  failureMessage: string | null;
  isLoggedIn: boolean;
}

const initialState: IUserState = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  countFreeReport: null,
  balance: null,
  role: '',
  registered: '',
  getUserProcessing: false,
  getUserFailure: false,
  showFailureMessage: false,
  failureMessage: null,
  isLoggedIn: false,
};

const getUserCredentialsReducer = (state = initialState, action: TApplicationActions) => {
  switch (action.type) {
    case GET_USER_CREDENTIALS: {
      return {
        ...state,
        getUserProcessing: true,
        getUserFailure: false,
      };
    }
    case GET_USER_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        id: action.id,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        countFreeReport: action.countFreeReport,
        balance: action.balance,
        role: action.role,
        registered: action.registered,
        getUserProcessing: false,
        isLoggedIn: true,
      };
    }
    case GET_USER_CREDENTIALS_FAILURE: {
      return {
        ...state,
        getUserProcessing: false,
        getUserFailure: true,
        showFailureMessage: true,
        failureMessage: action.failureMessage,
        isLoggedIn: false,
      };
    }
    case USER_LOGOUT: {
      return { initialState };
    }
    case USER_RESET_FAILURE: {
      return {
        ...state,
        showFailureMessage: false,
        failureMessage: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default getUserCredentialsReducer;
