import {
  REPORT_HISTORY_FAILURE,
  REPORT_HISTORY_PROCESSING,
  REPORT_HISTORY_SUCCESS,
} from '../../utils/constants';
import { TApplicationActions } from '../actions/types';
import { ICheckPersonResponse } from '../../utils/check_person_interface';

interface IReportHistoryReducer {
  reportHistoryProcessing: boolean;
  reportHistoryFailure: boolean;
  reportHistory: ICheckPersonResponse[];
}

const initialState: IReportHistoryReducer = {
  reportHistoryProcessing: false,
  reportHistoryFailure: false,
  reportHistory: [],
};

const getReportHistory = (state = initialState, action: TApplicationActions) => {
  switch (action.type) {
    case REPORT_HISTORY_PROCESSING: {
      return {
        ...state,
        reportHistoryProcessing: true,
        reportHistoryFailure: false,
      };
    }
    case REPORT_HISTORY_SUCCESS: {
      return {
        ...state,
        reportHistory: action.reportHistory,
      };
    }
    case REPORT_HISTORY_FAILURE: {
      return {
        ...state,
        reportHistoryProcessing: false,
        reportHistoryFailure: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default getReportHistory;
