import React, { FC, Dispatch, SetStateAction } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { confirmEmail } from '../../utils/Api';
import PopupWithForm from '../PopupWithForm/PopupWithForm';
import { PATTERN_EMAIL_CONFIRMATION } from '../../utils/constants';
// import { useSelector } from '../../services/hooks/reduxHooks';
import styles from './EmailConfirmationPopup.module.css';
// import { RootState } from '../../services/actions/types';

interface EmailPopupProps {
  onClose: () => void;
  isPopupOpened: boolean;
  openSignInPopup: () => void;
  openSignUpPopup: () => void;
  userEmail: string;
  isLoggedIn: Function;
  isPreloaderShown: Function;
  isInfoPopupOpened: Function;
  infoText: Dispatch<SetStateAction<any>>;
}

const EmailConfirmationPopup: FC<EmailPopupProps> = ({
  onClose,
  isPopupOpened,
  openSignInPopup,
  openSignUpPopup,
  userEmail,
  isLoggedIn,
  isPreloaderShown,
  isInfoPopupOpened,
  infoText,
}): React.ReactElement => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  function handleSubmitForm() {
    isPreloaderShown(true);
    confirmEmail(userEmail, watch('emailConfirmation'))
      .then(() => {
        reset();
        isLoggedIn(true);
        navigate('/registereduser');
      })
      .catch((err) => {
        onClose();
        isInfoPopupOpened(true);

        infoText(`Ошибка: ${err.message} ${err.status}`);
      })
      .finally(() => {
        isPreloaderShown(false);
      });
  }

  const emailConfirmationError = errors?.emailConfirmation?.message?.toString();

  return (
    <PopupWithForm
      title="Введите код"
      name="emailConfirmation"
      buttonText="Подтвердить"
      onClose={onClose}
      onSubmit={handleSubmit(handleSubmitForm)}
      isFormValid={isValid}
      isPopupOpened={isPopupOpened}
      openSignInPopup={openSignInPopup}
      openSignUpPopup={openSignUpPopup}
    >
      <p className={styles.confirmationText}>Код подтверждения отправлен на почту</p>
      <label className={styles.inputTitle}>
        <input
          type="text"
          id="email-confirmation"
          placeholder="Код"
          className={styles.input}
          required
          {...register('emailConfirmation', {
            required: 'Введите шестизначный код',
            maxLength: {
              value: 6,
              message: 'Не более 6 символов',
            },
            minLength: {
              value: 2,
              message: 'Введите шестизначный код',
            },
            pattern: {
              value: PATTERN_EMAIL_CONFIRMATION,
              message: 'Некорректный код',
            },
          })}
        />
        <span className={styles.inputError}>{emailConfirmationError}</span>
      </label>
    </PopupWithForm>
  );
};

export default EmailConfirmationPopup;
