import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ru } from 'date-fns/locale/ru';
import { useNavigate } from 'react-router-dom';

import styles from './RequestForm.module.css';
import checkMark from '../../images/check-mark.svg';
import blueCheckMark from '../../images/blueCheckMark.svg';
import Button from '../Button/Button';
import { PATTERN_NAME, PATTERN_PASSPORT } from '../../utils/constants';
import { useDispatch, useSelector } from '../../services/hooks/reduxHooks';
import { checkPerson } from '../../services/actions/checkPersonActions';
import { apiCheck } from '../../utils/Api';

const RequestForm = ({
  personRequestInfo,
  isPreloaderShown,
  isInfoPopupOpened,
  infoText,
  isReportAgreed,
  onReportAgreed,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector((store) => store.currentUser.id);
  const {
    register,
    watch,
    formState: { errors, isValid },
    control,
    handleSubmit,
    reset,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const todayDate = new Date();

  registerLocale('ru', ru);

  const handleResetForm = () => {
    reset();
  };

  useEffect(() => {
    if (isReportAgreed) {
      checkPersonRequest();
      onReportAgreed(false);
    }
  }, [isReportAgreed]);

  // TODO Юзер ID поменять на нормалный

  const checkPersonRequest = () => {
    personRequestInfo({
      name: watch('firstName'),
      lastName: watch('lastName'),
      fatherName: watch('fatherName'),
      passport: watch('passport'),
      drivingLicence: watch('drivingLicence'),
      dateOfBirth: new Intl.DateTimeFormat('ru-RU').format(watch('dateOfBirth')),
      dateOfLicence: new Intl.DateTimeFormat('ru-RU').format(watch('dateOfLicence')),
    });
    isPreloaderShown(true);
    dispatch(
      checkPerson(
        watch('lastName'),
        watch('firstName'),
        watch('fatherName'),
        watch('passport'),
        watch('drivingLicence'),
        new Intl.DateTimeFormat('ru-RU').format(watch('dateOfBirth')),
        new Intl.DateTimeFormat('ru-RU').format(watch('dateOfLicence')),
        userId
      )
    )
      .then(() => {
        navigate('/report');
        reset();
      })
      .catch((err) => {
        isInfoPopupOpened(true);
        infoText(`Ошибка: ${err.message} код: ${err.status}`);
      })
      .finally(() => {
        isPreloaderShown(false);
      });
  };

  const onSubmit = () => {
    isPreloaderShown(true);
    apiCheck()
      .then((res) => {
        if (
          res.bankruptOk &&
          res.fsspOk &&
          res.gibddOk &&
          res.innOk &&
          res.passportOk &&
          res.rosFinMonOk &&
          res.selfEmplOk
        ) {
          checkPersonRequest();
        } else {
          const bankrupt = res.bankruptOk ? '' : ' Проверка  банкротства \n';
          const fssp = res.fsspOk ? '' : 'Проверка исполнительных производств \n';
          const gibdd = res.gibddOk ? '' : 'Проверка водительских прав \n';
          const inn = res.innOk ? '' : 'Проверка ИНН \n';
          const passport = res.passportOk ? '' : 'Проверка паспорта \n';
          const rosFinMon = res.rosFinMonOk ? '' : 'Проверка на террористов и экстремистов \n';
          const selfEmpl = res.selfEmplOk ? '' : 'Проверка самозанятости \n';
          isInfoPopupOpened(true);
          const notWorkingServices =
            bankrupt + fssp + gibdd + inn + passport + rosFinMon + selfEmpl;
          const infoMessage = `К сожалению, следующие сервисы на данный момент не работают:
          ${notWorkingServices} Желаете получить отчет без указанных проверок? `;

          infoText(infoMessage);
          isPreloaderShown(false);
        }
      })
      .catch((err) => {
        console.error(err);
        isPreloaderShown(false);
      });
  };

  const navigate = useNavigate();
  const values = getValues();

  return (
    <section className={styles.formSection}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <h2 className={styles.formTitle}>Введите данные для проверки</h2>
        <div className={styles.formBlock}>
          <label htmlFor="last-name" className={styles.inputTitle}>
            Фамилия*
            <input
              id="last-name"
              className={styles.input}
              {...register('lastName', {
                required: 'Поле обязательно к заполнению',
                minLength: {
                  value: 2,
                  message: 'Минимум 2 символа',
                },
                maxLength: {
                  value: 30,
                  message: 'Максимум 30 символов',
                },
                pattern: {
                  value: PATTERN_NAME,
                  message: 'Введены недопустимые символы',
                },
              })}
            />
          </label>
          <p className={styles.error}>{errors?.lastName?.message}</p>
          <label htmlFor="first-name" className={styles.inputTitle}>
            Имя*
            <input
              id="first-name"
              className={styles.input}
              {...register('firstName', {
                required: 'Поле обязательно к заполнению',
                minLength: {
                  value: 2,
                  message: 'Минимум 2 символа',
                },
                maxLength: {
                  value: 30,
                  message: 'Максимум 30 символов',
                },
                pattern: {
                  value: PATTERN_NAME,
                  message: 'Введены недопустимые символы',
                },
              })}
            />
            <p className={styles.error}>{errors?.firstName?.message}</p>
          </label>
          <label htmlFor="father-name" className={styles.inputTitle}>
            Отчество*
            <input
              id="father-name"
              className={styles.input}
              {...register('fatherName', {
                required: 'Поле обязательно к заполнению',
                minLength: {
                  value: 2,
                  message: 'Минимум 2 символа',
                },
                maxLength: {
                  value: 30,
                  message: 'Максимум 30 символов',
                },
                pattern: {
                  value: PATTERN_NAME,
                  message: 'Введены недопустимые символы',
                },
              })}
            />
            <p className={styles.error}>{errors?.fatherName?.message}</p>
          </label>

          <Controller
            control={control}
            name="dateOfBirth"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label htmlFor="date-of-birth" className={styles.inputTitle}>
                Дата рождения*
                <DatePicker
                  onChange={onChange} // send value to hook form
                  onBlur={onBlur} // notify when input is touched/blur
                  selected={value}
                  locale="ru"
                  minDate="1900"
                  maxDate={todayDate}
                  showYearDropdown
                  dateFormat="dd.MM.yyyy"
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  changeMonth
                  changeYear
                  className={`${styles.input} ${styles.datePicker} `}
                  popperClassName={styles.datePickerMy}
                  // dayClassName={styles.datePickerDay}
                />
                <p className={styles.error} />
              </label>
            )}
          />

          <label htmlFor="passport" className={styles.inputTitle}>
            Серия и номер паспорта*
            <input
              id="passport"
              className={styles.input}
              {...register('passport', {
                required: 'Поле обязательно к заполнению',

                pattern: {
                  value: PATTERN_PASSPORT,
                  message: 'Введите серию и номер паспорта в виде 4000980015',
                },
                maxLength: {
                  value: 10,
                  message: 'Максимум 10 символов',
                },
              })}
            />
          </label>
          <p className={styles.error}>{errors?.passport?.message}</p>

          <label htmlFor="driving-licence" className={styles.inputTitle}>
            Водительское удостоверение
            <input
              id="driving-licence"
              className={`${styles.input}`}
              {...register('drivingLicence', {
                pattern: {
                  value: PATTERN_PASSPORT,
                  message: 'Введите водительское удостоверения в виде 4000980015',
                },
                maxLength: {
                  value: 10,
                  message: 'Максимум 10 символов',
                },
              })}
            />
            <p className={styles.error}>{errors?.drivingLicence?.message}</p>
          </label>

          <Controller
            control={control}
            name="dateOfLicence"
            render={({ field: { onChange, onBlur, value } }) => (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label htmlFor="date-of-licence" className={`${styles.inputTitle}`}>
                Дата выдачи водительского удостоверения
                <DatePicker
                  onChange={onChange} // send value to hook form
                  onBlur={onBlur} // notify when input is touched/blur
                  selected={value}
                  locale="ru"
                  minDate="1900"
                  maxDate={todayDate}
                  showYearDropdown
                  dateFormat="dd.MM.yyyy"
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  changeMonth
                  changeYear
                  className={`${styles.input} `}
                  popperClassName={styles.datePickerMy}
                />
              </label>
            )}
          />
        </div>
        <div className={styles.btnBlock}>
          <Button
            type="submit"
            btnText="Получить отчет"
            isBtnBlue
            isBtnDisabled={!isValid}
            isButtonReport
          />
          <Button
            type="button"
            btnText="Очистить форму"
            isButtonReport
            isBtnBlue={false}
            isBtnDisabled={false}
            onClick={handleResetForm}
            isButtonLarge={false}
          />
        </div>
      </form>
      <div className={styles.description}>
        <ul className={styles.descriptionTitle}>Проверка по следующим параметрам</ul>
        <li className={styles.descriptionBlock}>
          <img
            className={styles.descriptionCheckMark}
            src={
              values.firstName &&
              values.lastName &&
              values.fatherName &&
              values.dateOfBirth &&
              !(
                errors?.firstName?.message ||
                errors?.lastName?.message ||
                errors?.fatherName?.message ||
                errors?.passport?.message
              )
                ? blueCheckMark
                : checkMark
            }
            alt="галка"
          />
          <p className={styles.descriptionText}>Наличие исполнительных производств</p>
        </li>
        <li className={styles.descriptionBlock}>
          <img
            className={styles.descriptionCheckMark}
            src={
              values.firstName &&
              values.lastName &&
              values.fatherName &&
              values.dateOfBirth &&
              !(
                errors?.firstName?.message ||
                errors?.lastName?.message ||
                errors?.fatherName?.message ||
                errors?.passport?.message
              )
                ? blueCheckMark
                : checkMark
            }
            alt="галка"
          />
          <p className={styles.descriptionText}>Узнать ИНН</p>
        </li>
        <li className={styles.descriptionBlock}>
          <img
            className={styles.descriptionCheckMark}
            src={
              values.firstName &&
              values.lastName &&
              values.fatherName &&
              values.dateOfBirth &&
              !(
                errors?.firstName?.message ||
                errors?.lastName?.message ||
                errors?.fatherName?.message ||
                errors?.passport?.message
              )
                ? blueCheckMark
                : checkMark
            }
            alt="галка"
          />
          <p className={styles.descriptionText}>Действительность паспорта</p>
        </li>
        <li className={styles.descriptionBlock}>
          <img
            className={styles.descriptionCheckMark}
            src={
              values.firstName &&
              values.lastName &&
              values.fatherName &&
              values.dateOfBirth &&
              !(
                errors?.firstName?.message ||
                errors?.lastName?.message ||
                errors?.fatherName?.message ||
                errors?.passport?.message
              )
                ? blueCheckMark
                : checkMark
            }
            alt="галка"
          />
          <p className={styles.descriptionText}>Наличие банкротства</p>
        </li>
        <li className={styles.descriptionBlock}>
          <img
            className={`${styles.descriptionCheckMark} `}
            src={
              watch('drivingLicence') && watch('dateOfLicence') && !errors?.drivingLicence?.message
                ? blueCheckMark
                : checkMark
            }
            alt="галка"
          />

          <p className={styles.descriptionText}>Действительность водительских прав</p>
        </li>
        <li className={styles.descriptionBlock}>
          <img
            className={styles.descriptionCheckMark}
            src={
              watch('firstName') &&
              watch('lastName') &&
              watch('fatherName') &&
              watch('dateOfBirth') &&
              !(
                errors?.firstName?.message ||
                errors?.lastName?.message ||
                errors?.fatherName?.message ||
                errors?.passport?.message
              )
                ? blueCheckMark
                : checkMark
            }
            alt="галка"
          />
          <p className={styles.descriptionText}>Является ли человек самозанятым</p>
        </li>
        <li className={styles.descriptionBlock}>
          <img
            className={styles.descriptionCheckMark}
            src={
              values.firstName &&
              values.lastName &&
              values.fatherName &&
              values.dateOfBirth &&
              !(
                errors?.firstName?.message ||
                errors?.lastName?.message ||
                errors?.fatherName?.message ||
                errors?.passport?.message
              )
                ? blueCheckMark
                : checkMark
            }
            alt="галка"
          />
          <p className={styles.descriptionText}>Проверка на террориста</p>
        </li>

        <li className={styles.descriptionBlock}>
          <img
            className={styles.descriptionCheckMark}
            src={
              values.firstName &&
              values.lastName &&
              values.fatherName &&
              values.dateOfBirth &&
              !(
                errors?.firstName?.message ||
                errors?.lastName?.message ||
                errors?.fatherName?.message ||
                errors?.passport?.message
              )
                ? blueCheckMark
                : checkMark
            }
            alt="галка"
          />
          <p className={styles.descriptionText}>Индивидуальное предпринимательство</p>
        </li>

        <li className={styles.descriptionBlock}>
          <img
            className={styles.descriptionCheckMark}
            src={
              values.firstName &&
              values.lastName &&
              values.fatherName &&
              values.dateOfBirth &&
              !(
                errors?.firstName?.message ||
                errors?.lastName?.message ||
                errors?.fatherName?.message ||
                errors?.passport?.message
              )
                ? blueCheckMark
                : checkMark
            }
            alt="галка"
          />
          <p className={styles.descriptionText}>Ограничение занимать должность</p>
        </li>

        <li className={styles.descriptionBlock}>
          <img
            className={styles.descriptionCheckMark}
            src={
              values.firstName &&
              values.lastName &&
              values.fatherName &&
              values.dateOfBirth &&
              !(
                errors?.firstName?.message ||
                errors?.lastName?.message ||
                errors?.fatherName?.message ||
                errors?.passport?.message
              )
                ? blueCheckMark
                : checkMark
            }
            alt="галка"
          />
          <p className={styles.descriptionText}>Проверка ФСИН</p>
        </li>
      </div>
    </section>
  );
};

RequestForm.propTypes = {
  personRequestInfo: PropTypes.func,
  isPreloaderShown: PropTypes.func,
  isInfoPopupOpened: PropTypes.func,
  infoText: PropTypes.func,
  isReportAgreed: PropTypes.bool,
  onReportAgreed: PropTypes.func,
};

RequestForm.defaultProps = {
  personRequestInfo: undefined,
  isPreloaderShown: undefined,
  isInfoPopupOpened: undefined,
  infoText: undefined,
  isReportAgreed: false,
  onReportAgreed: undefined,
};
export default RequestForm;
