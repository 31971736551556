import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
// import { useSelector } from '../../services/hooks/reduxHooks';

interface IProtectedRouteElement {
  element: React.ReactElement;
  isLoggedIn: boolean;
}
// { element: Component }
const ProtectedRouteElement: FC<IProtectedRouteElement> = ({ isLoggedIn, element }) => {
  // @ts-ignore
  // TODO
  // const { isLoggedIn } = useSelector((store) => store.currentUser);
  // eslint-disable-next-line no-restricted-syntax
  // console.log(isLoggedIn);
  return isLoggedIn ? element : <Navigate to="/" />;
};

export default ProtectedRouteElement;
