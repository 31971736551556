import React, { FC, Dispatch, SetStateAction } from 'react';

import RequestForm from '../../components/RequestForm/RequestForm';

interface AuthorisedUserPageProps {
  personRequestInfo: Dispatch<
    SetStateAction<{
      name: string;
      lastName: string;
      fatherName: string;
      passport: string;
      drivingLicence: string;
      dateOfBirth: string;
      dateOfLicence: string;
    }>
  >;
  isPreloaderShown: Dispatch<SetStateAction<boolean>>;
  isInfoPopupOpened: Dispatch<SetStateAction<any>>;
  infoText: Dispatch<SetStateAction<any>>;
  isReportAgreed: boolean;
  onReportAgreed: Dispatch<SetStateAction<boolean>>;
}

const AuthorisedUserPage: FC<AuthorisedUserPageProps> = ({
  personRequestInfo,
  isPreloaderShown,
  isInfoPopupOpened,
  infoText,
  isReportAgreed,
  onReportAgreed,
}): React.ReactElement => {
  return (
    <RequestForm
      personRequestInfo={personRequestInfo}
      isPreloaderShown={isPreloaderShown}
      isInfoPopupOpened={isInfoPopupOpened}
      infoText={infoText}
      isReportAgreed={isReportAgreed}
      onReportAgreed={onReportAgreed}
    />
  );
};

export default AuthorisedUserPage;
