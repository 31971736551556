// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/OpenSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/OpenSans-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-family: 'Open Sans';
  font-weight: 400;
}

@font-face {
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-family: 'Open Sans';
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/vendor/fonts.css"],"names":[],"mappings":"AAAA;EACE,4CAAuC;EACvC,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,4CAAoC;EACpC,wBAAwB;EACxB,gBAAgB;AAClB","sourcesContent":["@font-face {\n  src: url(../fonts/OpenSans-Regular.ttf);\n  font-family: 'Open Sans';\n  font-weight: 400;\n}\n\n@font-face {\n  src: url(../fonts/OpenSans-Bold.ttf);\n  font-family: 'Open Sans';\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
