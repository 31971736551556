import React, { FC } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import styles from './AdminPanel.module.css';
import stylesLink from '../../pages/MainPage/MainPage.module.css';
import userLogo from '../../images/user.svg';
import edit from '../../images/edit.svg';

interface FormData {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
}

const AdminPanel: FC = (): React.ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    /* eslint-disable no-restricted-syntax */
    console.log(data);
  };

  return (
    <section className={styles.panel}>
      <div className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.userContainer}>
          <div className={styles.userInfo}>
            <div className={styles.userData}>
              <label className={styles.labelIcon}>
                <img src={userLogo} alt="иконка пользователя" className={styles.userIcon} />
              </label>
              <div className={styles.fullName}>
                <input
                  className={styles.userFirstName}
                  value="Админ"
                  type="text"
                  id="firstName"
                  {...register('firstName', { required: true })}
                />
                {errors.firstName && <span>Поле Имя обязательно для заполнения</span>}
                <input
                  className={styles.userLastName}
                  value="Админович"
                  type="text"
                  id="lastName"
                  {...register('lastName', { required: true })}
                />
                {errors.lastName && <span>Поле Фамилия обязательно для заполнения</span>}
              </div>
            </div>
            <div className={styles.userCred}>
              <div className={styles.userPasswordContainer}>
                <label className={styles.userPasswordLabel} htmlFor="password">
                  Пароль:
                </label>
                <input
                  className={styles.userPassword}
                  value="*******"
                  type="password"
                  id="password"
                  {...register('password', { required: true })}
                />
                {errors.password && <span>Поле Пароль обязательно для заполнения</span>}
              </div>
              <div className={styles.userEmailContainer}>
                <label className={styles.userEmailLabel} htmlFor="email">
                  Email:
                </label>
                <input
                  className={styles.userEmail}
                  value="yandex@yandex.ru"
                  type="email"
                  id="email"
                  {...register('email', { required: true })}
                />
                {errors.email && <span>Поле Email обязательно для заполнения</span>}
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <button type="submit" className={`${styles.edit} ${stylesLink.link}`}>
              <img src={edit} alt="иконка изменения данных" className={styles.editImg} />
            </button>
            <button type="button" className={`${styles.exit} ${stylesLink.link}`}>
              Выйти
            </button>
          </div>
        </form>
        <div className={styles.reportContainer}>
          <p className={styles.reportsText}>Всего отчетов на сегодня</p>
          <p className={styles.reportsCount}>28</p>
        </div>
        <div className={styles.moneyContainer}>
          <p className={styles.moneyText}>Доступно на сегодня</p>
          <p className={styles.moneyValue}>700 ₽</p>
          <button type="button" className={`${styles.moneyBtn} ${stylesLink.link}`}>
            Пополнить
          </button>
        </div>
      </div>
    </section>
  );
};

export default AdminPanel;
