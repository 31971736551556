import React, { FC } from 'react';
import styles from './InfoPopup.module.css';
import closeButtonImage from '../../images/close-button.svg';

interface InfoPopupProps {
  infoText: string;
  onClose: () => void;
  isInfoPopupOpened: boolean;
  children: any;
}

const InfoPopup: FC<InfoPopupProps> = ({
  infoText,
  onClose,
  isInfoPopupOpened,
  children,
}): React.ReactElement => {
  document.addEventListener('keydown', closeByEscape);
  document.addEventListener('mouseup', handeMouseClosePopup);

  function closeByEscape(evt: { key: string }) {
    if (evt.key === 'Escape') {
      onClose();
    }
  }

  function handeMouseClosePopup(event: MouseEvent) {
    if (event.target === document.querySelector('.InfoPopup_popupOpened__k8vCD')) {
      onClose();
    }
  }

  return (
    <section className={`${styles.popup} ${isInfoPopupOpened ? styles.popupOpened : ''}`}>
      <div className={styles.popupContainer}>
        <button className={styles.closeButton} type="button" onClick={onClose}>
          {}
          <img className={styles.closeButtonImg} src={closeButtonImage} alt="Закрыть" />
        </button>
        <p className={styles.text}>{infoText}</p>
        {children}
      </div>
    </section>
  );
};

export default InfoPopup;
