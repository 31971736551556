// Actions constants

const GET_USER_CREDENTIALS: 'GET_USER_CREDENTIALS' = 'GET_USER_CREDENTIALS';
const GET_USER_CREDENTIALS_SUCCESS: 'GET_USER_CREDENTIALS_SUCCESS' = 'GET_USER_CREDENTIALS_SUCCESS';
const GET_USER_CREDENTIALS_FAILURE: 'GET_USER_CREDENTIALS_FAILURE' = 'GET_USER_CREDENTIALS_FAILURE';
const USER_LOGOUT: 'USER_LOGOUT' = 'USER_LOGOUT';
const USER_RESET_FAILURE: 'USER_RESET_FAILURE' = 'USER_RESET_FAILURE';

const CHECK_PERSON: 'CHECK_PERSON' = 'CHECK_PERSON';
const CHECK_PERSON_SUCCESS: 'CHECK_PERSON_SUCCESS' = 'CHECK_PERSON_SUCCESS';
const CHECK_PERSON_FAILURE: 'CHECK_PERSON_FAILURE' = 'CHECK_PERSON_FAILURE';
const CHECK_PERSON_RESET_FAILURE: 'CHECK_PERSON_RESET_FAILURE' = 'CHECK_PERSON_RESET_FAILURE';
const CHECK_PERSON_OFFLINE: 'CHECK_PERSON_OFFLINE' = 'CHECK_PERSON_OFFLINE';
const CHECK_PERSON_RESET: 'CHECK_PERSON_RESET' = 'CHECK_PERSON_RESET';

const REPORT_HISTORY_PROCESSING: 'REPORT_HISTORY_PROCESSING' = 'REPORT_HISTORY_PROCESSING';
const REPORT_HISTORY_SUCCESS: 'REPORT_HISTORY_SUCCESS' = 'REPORT_HISTORY_SUCCESS';
const REPORT_HISTORY_FAILURE: 'REPORT_HISTORY_FAILURE' = 'REPORT_HISTORY_FAILURE';
const REPORT_HISTORY__RESET_FAILURE: 'REPORT_HISTORY__RESET_FAILURE' =
  'REPORT_HISTORY__RESET_FAILURE';

const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,4}$/;
const PATTERN_NAME = /^(?=.{1,30}$)[а-яёА-ЯЁ\s]+(?:[-][а-яёА-ЯЁ]+)*$/;
const PATTERN_PASSPORT = /^(\d *){10}$/;
const PATTERN_PASSWORD = /^(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*/;
const PATTERN_EMAIL_CONFIRMATION = /^(\d *){6}$/;

export {
  GET_USER_CREDENTIALS,
  GET_USER_CREDENTIALS_SUCCESS,
  GET_USER_CREDENTIALS_FAILURE,
  USER_LOGOUT,
  USER_RESET_FAILURE,
  CHECK_PERSON,
  CHECK_PERSON_RESET,
  CHECK_PERSON_SUCCESS,
  CHECK_PERSON_FAILURE,
  CHECK_PERSON_RESET_FAILURE,
  CHECK_PERSON_OFFLINE,
  EMAIL_REGEXP,
  PATTERN_NAME,
  PATTERN_PASSPORT,
  PATTERN_PASSWORD,
  PATTERN_EMAIL_CONFIRMATION,
  REPORT_HISTORY_PROCESSING,
  REPORT_HISTORY_SUCCESS,
  REPORT_HISTORY_FAILURE,
  REPORT_HISTORY__RESET_FAILURE,
};
