import React, { FC } from 'react';
import styles from './Admin.module.css';
import AdminPanel from '../../components/AdminPanel/AdminPanel';

const Admin: FC = (): React.ReactElement => {
  return (
    <main className={styles.admin}>
      <AdminPanel />
    </main>
  );
};

export default Admin;
