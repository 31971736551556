import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import report from '../../images/reportLanding.png';
import styles from './Landing.module.css';
// import stylesLink from '../../pages/MainPage/MainPage.module.css';
import { useSelector } from '../../services/hooks/reduxHooks';
import Button from '../Button/Button';

interface LandingProps {
  isSignInPopupOpened: () => void;
}

const Landing: FC<LandingProps> = ({ isSignInPopupOpened }): React.ReactElement => {
  // @ts-ignore
  const { isLoggedIn } = useSelector((store) => store.currentUser);

  const openSignInPopup = () => {
    isSignInPopupOpened();
  };

  const navigate = useNavigate();

  const handleRequest = () => {
    isLoggedIn ? navigate('/registereduser') : openSignInPopup();
  };
  return (
    <section className={styles.landing}>
      <div className={styles.container}>
        <div className={styles.preview}>
          <div className={styles.previewInfo}>
            <h1 className={styles.title}>Быстрая проверка физических лиц</h1>
            <p className={styles.subtitle}>Введите данные для проверки и получите отчет</p>
            <Button
              type="button"
              btnText="Попробовать бесплатно"
              isBtnBlue
              isBtnDisabled={false}
              onClick={handleRequest}
              isButtonLanding
            />
            {/* <button
              type="submit"
              className={`${styles.btn} ${stylesLink.link}`}
              onClick={handleRequest}
            >
              Попробовать бесплатно
            </button> */}
          </div>
          <div className={styles.previewForm}>
            <div className={styles.formImage}>
              <img src={report} alt="превью формы запроса" className={styles.formImg} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Landing;
