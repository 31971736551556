import React, { FC, ReactNode } from 'react';
import styles from './PopupWithForm.module.css';
import closeButtonImage from '../../images/close-button.svg';
import Button from '../Button/Button';

interface PopupWithFormProps {
  title: string;
  name: string;
  buttonText: string;
  children: ReactNode;
  onClose: () => void;
  onSubmit: () => void;
  isFormValid: boolean;
  isPopupOpened: boolean;
  openSignInPopup: () => void;
  openSignUpPopup: () => void;
}

const PopupWithForm: FC<PopupWithFormProps> = ({
  title,
  name,
  buttonText,
  children,
  onClose,
  onSubmit,
  isFormValid,
  isPopupOpened,
  openSignInPopup,
  openSignUpPopup,
}): React.ReactElement => {
  document.addEventListener('keydown', closeByEscape);
  document.addEventListener('mouseup', handeMouseClosePopup);

  function closeByEscape(evt: { key: string }) {
    if (evt.key === 'Escape') {
      onClose();
    }
  }

  function handeMouseClosePopup(event: MouseEvent) {
    if (event.target === document.querySelector('.PopupWithForm_popupOpened__K9YpT')) {
      onClose();
    }
  }

  const handleSignUp = () => {
    onClose();
    openSignUpPopup();
  };

  const handleSignIn = () => {
    onClose();
    openSignInPopup();
  };

  return (
    <section className={`${styles.popup} ${isPopupOpened ? styles.popupOpened : ''}`}>
      <div className={styles.popupContainer}>
        <form className={styles.form} noValidate onSubmit={onSubmit}>
          <button className={styles.closeButton} type="button" onClick={onClose}>
            {}
            <img className={styles.closeButtonImg} src={closeButtonImage} alt="Закрыть" />
          </button>
          <h2 className={styles.title}>{title}</h2>
          {children}
          <Button
            type="submit"
            btnText={buttonText}
            isBtnBlue
            isButtonLarge
            isBtnDisabled={!isFormValid}
          />
        </form>
        {name === 'signIn' && (
          <div className={styles.regSugestion}>
            Ещё нет аккаунта?
            <button type="button" className={styles.link} onClick={handleSignUp}>
              Зарегистрируйтесь
            </button>
          </div>
        )}
        {name === 'signUp' && (
          <div className={styles.regSugestion}>
            Есть аккаунт?
            <button type="button" className={styles.link} onClick={handleSignIn}>
              Вход
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default PopupWithForm;
