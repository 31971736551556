// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainPage_main__CnV-R {
  font-family: 'Roboto', sans-serif;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
}

.MainPage_link__CqA7a {
  transition: 0.5s;
}

.MainPage_link__CqA7a:hover {
  cursor: pointer;
  opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/MainPage.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,YAAY;AACd","sourcesContent":[".main {\n  font-family: 'Roboto', sans-serif;\n  max-width: 1440px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  flex: 1 0 auto;\n}\n\n.link {\n  transition: 0.5s;\n}\n\n.link:hover {\n  cursor: pointer;\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `MainPage_main__CnV-R`,
	"link": `MainPage_link__CqA7a`
};
export default ___CSS_LOADER_EXPORT___;
