import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './Slider.module.css';
// import image from '../../images/mvdIcon.png';

const SimpleSlider = () => {
  // const [sliderRef, setSliderRef] = useState(null);
  const settings = {
    arrows: false,
    // slidesToShow: 1.96,
    // slidesToScroll: 1,
    infinite: true,
    // speed: 500,
    // centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,

    // responsive: [
    //   {
    //     breakpoint: 1440,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 700,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
    // appendArrows: '.your-class-arrow',

    // nextArrow: '<a className={styles.nextArrow}></a>',
  };
  return (
    <div className={styles.sliderContainer}>
      <Slider {...settings}>
        <div className={styles.column}>
          <div className={styles.item}>
            <h3 className={styles.columnTitle}>Обновить данные сотрудников</h3>
            <p className={styles.columnText}>Из открытых источников, сразу для вас</p>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.item}>
            <h3 className={styles.columnTitle}>Хранить документы в удобном формате</h3>

            <p className={styles.columnText}>Можно скачать и увидеть в личном кабинете</p>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.item}>
            <h3 className={styles.columnTitle}>Поможем узнать самое главное</h3>
            <p className={styles.columnText}>Действительность документов</p>
          </div>
        </div>

        {/* </div> */}
      </Slider>
    </div>
  );
};

export default SimpleSlider;
