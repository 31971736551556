// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Admin_admin__Mndqh {
  font-family: 'Roboto', sans-serif;
  background: #f6f6f6;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Admin.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,mBAAmB;AACrB","sourcesContent":[".admin {\n  font-family: 'Roboto', sans-serif;\n  background: #f6f6f6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin": `Admin_admin__Mndqh`
};
export default ___CSS_LOADER_EXPORT___;
