import React, { FC, Dispatch, SetStateAction } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../utils/Api';
import { EMAIL_REGEXP, PATTERN_PASSWORD } from '../../utils/constants';

import PopupWithForm from '../PopupWithForm/PopupWithForm';
import styles from './SignInPopup.module.css';
import showPasswordImage from '../../images/eye.svg';
import unshowPasswordImage from '../../images/showPassword.svg';

interface SignInPopupProps {
  onClose: () => void;
  isPopupOpened: boolean;
  openSignInPopup: () => void;
  openSignUpPopup: () => void;
  isPreloaderShown: Function;
  isLoggedIn: Function;
  isInfoPopupOpened: Function;
  infoText: Dispatch<SetStateAction<any>>;
}

const SignInPopup: FC<SignInPopupProps> = ({
  onClose,
  isPopupOpened,
  openSignInPopup,
  openSignUpPopup,
  isPreloaderShown,
  isLoggedIn,
  isInfoPopupOpened,
  infoText,
}): React.ReactElement => {
  const [showPassword, setShowPassword] = React.useState(false);

  const navigate = useNavigate();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  function togglePasswordVisibility(): void {
    setShowPassword(!showPassword);
  }

  function handleKeyPress(e: React.KeyboardEvent): void {
    if (e.key === 'End') {
      togglePasswordVisibility();
    }
  }

  function handleSubmitForm() {
    isPreloaderShown(true);
    loginUser(watch('email'), watch('password'))
      .then(() => {
        //  dispatch(updateUser());
        isLoggedIn(true);
        reset();
        onClose();
        navigate('/registereduser');
      })
      .catch((err) => {
        onClose();
        isInfoPopupOpened(true);
        infoText(`Ошибка: ${err.message} код: ${err.status}`);
      })
      .finally(() => {
        isPreloaderShown(false);
      });
  }

  const emailError = errors?.email?.message?.toString();
  const passwordError = errors?.password?.message?.toString();

  return (
    <PopupWithForm
      title="Вход"
      name="signIn"
      buttonText="Войти"
      onClose={onClose}
      onSubmit={handleSubmit(handleSubmitForm)}
      isFormValid={isValid}
      isPopupOpened={isPopupOpened}
      openSignInPopup={openSignInPopup}
      openSignUpPopup={openSignUpPopup}
    >
      <label className={styles.inputTitle}>
        Email*
        <input
          type="email"
          id="email"
          placeholder="Почта"
          className={styles.input}
          required
          {...register('email', {
            required: 'Текст должен содержать не менее 2-х символов',
            pattern: {
              value: EMAIL_REGEXP,
              message: 'Введите корректный адрес электронной почты',
            },
          })}
        />
        <span className={styles.inputError}>{emailError}</span>
      </label>
      <label className={styles.inputTitle}>
        Пароль*
        <input
          type={showPassword ? 'text' : 'password'}
          id="password"
          placeholder="Пароль"
          className={styles.input}
          onKeyDown={handleKeyPress}
          required
          {...register('password', {
            required: 'Текст должен содержать не менее 2-х символов',
            maxLength: {
              value: 40,
              message: 'Текст должен содержать не более 40 символов',
            },
            minLength: {
              value: 8,
              message:
                'Минимум 8 символов, одна цифра, спецсимвол, одна буква в верхнем регистре и одна в нижнем',
            },
            pattern: {
              value: PATTERN_PASSWORD,
              message:
                'Минимум 8 символов, одна цифра, спецсимвол, одна буква в верхнем регистре и одна в нижнем',
            },
          })}
        />
        <button className={styles.toggleBtn} onClick={togglePasswordVisibility} type="button">
          {}
          <img
            className={styles.togglePasswordImg}
            src={showPassword ? unshowPasswordImage : showPasswordImage}
            alt="Показать пароль"
          />
        </button>
        <span className={styles.inputError}>{passwordError}</span>
      </label>
    </PopupWithForm>
  );
};

export default SignInPopup;
