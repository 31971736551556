/* eslint-disable react/jsx-indent-props */
import React, { FC, Dispatch, SetStateAction } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useForm } from 'react-hook-form';

import PopupWithForm from '../PopupWithForm/PopupWithForm';
import { EMAIL_REGEXP, PATTERN_NAME, PATTERN_PASSWORD } from '../../utils/constants';
import styles from './SignUpPopup.module.css';
import showPasswordImage from '../../images/eye.svg';
import unshowPasswordImage from '../../images/showPassword.svg';
import { registerUser } from '../../utils/Api';

interface SignUpPopupProps {
  onClose: () => void;
  isPopupOpened: boolean;
  openSignInPopup: () => void;
  openSignUpPopup: () => void;
  openEmailConfirmationPopup: () => void;
  userEmail: Function;
  isPreloaderShown: Function;
  isInfoPopupOpened: Function;
  infoText: Dispatch<SetStateAction<any>>;
}

const SignUpPopup: FC<SignUpPopupProps> = ({
  onClose,
  isPopupOpened,
  openSignInPopup,
  openSignUpPopup,
  openEmailConfirmationPopup,
  userEmail,
  isPreloaderShown,
  isInfoPopupOpened,
  infoText,
}): React.ReactElement => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [dataCheckboxValue, setDataCheckboxValue] = React.useState(true);
  const [privacyCheckboxValue, setPrivacyCheckboxValue] = React.useState(true);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  function togglePasswordVisibility(): void {
    setShowPassword(!showPassword);
  }

  function toggleDataCheckBoxValue(): void {
    setDataCheckboxValue(!dataCheckboxValue);
  }

  function togglePrivacyCheckBoxValue(): void {
    setPrivacyCheckboxValue(!privacyCheckboxValue);
  }

  function handleKeyPress(e: React.KeyboardEvent): void {
    if (e.key === 'End') {
      togglePasswordVisibility();
    }
  }

  const handleConfirmEmail = () => {
    onClose();
    openEmailConfirmationPopup();
  };

  const handleSubmitForm = () => {
    isPreloaderShown(true);
    userEmail(watch('email'));

    registerUser(watch('firstName'), watch('lastName'), watch('email'), watch('password'))
      .then(() => {
        handleConfirmEmail();
        reset();
      })
      .catch((err) => {
        onClose();
        if (err.status === 400) {
          infoText(`Введен неккоректный пароль`);
          isInfoPopupOpened(true);
        } else {
          isInfoPopupOpened(true);
          infoText(`Ошибка: ${err.message} код: ${err.status}`);
        }
      })
      .finally(() => {
        isPreloaderShown(false);
      });
  };

  const firstNameError: string | undefined = errors?.firstName?.message?.toString();
  const lastNameError = errors?.lastName?.message?.toString();
  const emailError = errors?.email?.message?.toString();
  const passwordError = errors?.password?.message?.toString();
  const persDataError = errors?.dataCheckbox?.message?.toString();
  const policiError = errors?.privacyCheckbox?.message?.toString();

  return (
    <PopupWithForm
      title="Регистрация"
      name="signUp"
      buttonText="Зарегистрироваться"
      onClose={onClose}
      onSubmit={handleSubmit(handleSubmitForm)}
      isFormValid={isValid}
      isPopupOpened={isPopupOpened}
      openSignInPopup={openSignInPopup}
      openSignUpPopup={openSignUpPopup}
    >
      <label className={styles.inputTitle}>
        Имя*
        <input
          type="text"
          id="popup__name"
          // placeholder="Имя"
          className={styles.input}
          required
          {...register('firstName', {
            required: 'Поле обязательно к заполнению',
            minLength: {
              value: 2,
              message: 'Минимум 2 символа',
            },
            maxLength: {
              value: 30,
              message: 'Максимум 30 символов',
            },
            pattern: {
              value: PATTERN_NAME,
              message: 'Введены недопустимые символы',
            },
          })}
        />
        <span className={styles.inputError}>{firstNameError}</span>
      </label>
      <label className={styles.inputTitle}>
        Фамилия*
        <input
          type="text"
          id="popup__surname"
          // placeholder="Фамилия"
          className={styles.input}
          required
          {...register('lastName', {
            required: 'Поле обязательно к заполнению',
            minLength: {
              value: 2,
              message: 'Минимум 2 символа',
            },
            maxLength: {
              value: 30,
              message: 'Максимум 30 символов',
            },
            pattern: {
              value: PATTERN_NAME,
              message: 'Введены недопустимые символы',
            },
          })}
        />
        <span className={styles.inputError}>{lastNameError}</span>
      </label>
      <label className={styles.inputTitle}>
        Почта*
        <input
          type="url"
          id="popup__signup"
          // placeholder="Почта"
          className={styles.input}
          onKeyDown={handleKeyPress}
          required
          {...register('email', {
            pattern: {
              value: EMAIL_REGEXP,
              message: 'Введите корректный адрес электронной почты',
            },
          })}
        />
        <span className={styles.inputError}>{emailError}</span>
      </label>
      <label className={styles.inputTitle}>
        Пароль*
        <input
          type={showPassword ? 'text' : 'password'}
          id="popup__password"
          // placeholder="Пароль"
          className={styles.input}
          required
          {...register('password', {
            required: 'Поле обязательно к заполнению',
            maxLength: {
              value: 40,
              message: 'Пароль не более 40 символов',
            },
            pattern: {
              value: PATTERN_PASSWORD,
              message:
                'Минимум 8 символов, одна цифра, спецсимвол, одна буква в верхнем регистре и одна в нижнем',
            },
            minLength: {
              value: 8,
              message:
                'Минимум 8 символов, одна цифра, спецсимвол, одна буква в верхнем регистре и одна в нижнем',
            },
          })}
        />
        <button className={styles.toggleBtn} onClick={togglePasswordVisibility} type="button">
          {}
          <img
            className={styles.togglePasswordImg}
            src={showPassword ? unshowPasswordImage : showPasswordImage}
            alt="Показать пароль"
          />
        </button>
        <span className={styles.inputError}>{passwordError}</span>
      </label>

      <div className={styles.checkboxContainer}>
        <label htmlFor="data-checkbox" className={styles.checkboxBlock}>
          <input
            type="checkbox"
            id="data-checkbox"
            className={styles.customCheckbox}
            required
            value="personalData"
            checked={dataCheckboxValue}
            onClick={toggleDataCheckBoxValue}
            {...register('dataCheckbox', {
              required: 'Необходимо принять согласие на обработку персональных данных',
            })}
          />

          <span className={styles.checkbox}>{}</span>
          <span className={styles.checkboxTitle}>
            Даю согласие на обработку персональных данных и ознакомлен с Политикой
            конфиденциальности
          </span>
        </label>

        <span className={styles.inputError}>{persDataError}</span>
      </div>

      <div className={styles.checkboxContainer}>
        <label htmlFor="privacy-checkbox" className={styles.checkboxBlock}>
          <input
            type="checkbox"
            className={styles.customCheckbox}
            id="privacy-checkbox"
            required
            value="privacy"
            checked={privacyCheckboxValue}
            onClick={togglePrivacyCheckBoxValue}
            {...register('privacyCheckbox', {
              required: 'Необходимо принять политику конфиденциальности',
            })}
          />
          <span className={styles.checkbox}>{}</span>
          <span className={styles.checkboxTitle}>
            Подтверждаю, что ознакомился и согласен с Условиями пользования сервиса
          </span>
        </label>
        <span className={styles.inputError}>{policiError}</span>
      </div>
    </PopupWithForm>
  );
};

export default SignUpPopup;
