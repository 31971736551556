/* eslint-disable no-shadow */
import React, { FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useForm } from 'react-hook-form';
import JsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import styles from './Report.module.css';
// import { EMAIL_REGEXP } from '../../utils/constants';
import Button from '../Button/Button';

import {
  IReport,
  IFsspItem,
  IDecisItem,
  IRosFinMonItem,
  IBankruptResponseItem,
  IDisqualifiedResponse,
  IFsinResponse,
} from './reportInterface';

const Report: FC<IReport> = ({ personRequestInfo, report }): React.ReactElement => {
  const pdfRef = useRef(null);

  const navigate = useNavigate();

  const handleNewReport = () => {
    navigate('/registereduser');
  };

  // const handleSendEmail = () => {};

  const handleDownloadPDFClick = () => {
    handleDownloadPDF();
  };

  // Скачать отчёт в формате PDF
  const handleDownloadPDF = () => {
    const input = pdfRef.current;
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new JsPdf('p', 'mm');
        const imgWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = 295.5;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save(
          `${personRequestInfo.lastName} ${personRequestInfo.name} ${personRequestInfo.fatherName}.pdf`
        ); // сохранять как ФИО и дату
      });
    }
  };

  return (
    <section className={styles.report}>
      <div className={styles.reportBlock} ref={pdfRef}>
        {/* Данные проверяемого */}
        <h2 className={styles.personName}>
          {personRequestInfo.lastName} {personRequestInfo.name} {personRequestInfo.fatherName}
        </h2>
        <ul className={styles.personInfo}>
          <li>Дата рождения</li>
          <li>{personRequestInfo.dateOfBirth}</li>

          <li>Паспорт</li>
          <li>{personRequestInfo.passport}</li>

          <li>Водительское удостоверение</li>
          <li>{personRequestInfo.drivingLicence || 'Отсутствует в запросе'}</li>

          <li>ИНН</li>
          <li>{report?.report.innResponse.inn || 'Информация не найдена'}</li>
        </ul>

        {/* Краткие результаты проверки */}
        <h2 className={styles.subTitle}>Результаты проверки</h2>
        <ul className={styles.results}>
          <li>
            <a className={styles.checkLink} href="#passport">
              <span
                className={
                  report?.report.passportCheckResponse.result === 0
                    ? styles.greenMark
                    : styles.redMark
                }
              />
              Действительность паспорта
            </a>
          </li>
          <li>
            <a className={styles.checkLink} href="#bankrupt">
              <span
                className={
                  !report?.report.bankruptResponse.totalCount ? styles.greenMark : styles.redMark
                }
              />
              Банкротство
            </a>
          </li>
          <li>
            <a className={styles.checkLink} href="#isp">
              <span
                className={
                  report?.report.fsspResponse.count === 0 ? styles.greenMark : styles.redMark
                }
              />
              Исполнительные производства
            </a>
          </li>
          {report?.report.gibddResponse.status && (
            <li>
              <a className={styles.checkLink} href="#gibdd">
                <span
                  className={
                    !report?.report.gibddResponse.doc.division ||
                    report?.report.gibddResponse.decis !== null ||
                    report?.report.gibddResponse.message ===
                      'Переданы данные о чужом водительском удостоверении. Дата рождения не совпала с данными из базы ГИБДД'
                      ? styles.redMark
                      : styles.greenMark
                  }
                />
                Водительское удостоверение
              </a>
            </li>
          )}
          <li>
            <a className={styles.checkLink} href="#inn">
              <span
                className={report?.report.innResponse.inn ? styles.greenMark : styles.redMark}
              />
              ИНН
            </a>
          </li>
          <li>
            <a className={styles.checkLink} href="#terrorist">
              <span
                className={
                  !report?.report.rosFinMonResponse.found ? styles.greenMark : styles.redMark
                }
              />
              Террористы и экстремисты
            </a>
          </li>
          <li>
            <a className={styles.checkLink} href="#samoz">
              <span
                className={
                  report?.report.selfEmplResponse.inn !== null ? styles.greenMark : styles.redMark
                }
              />
              Самозанятость
            </a>
          </li>

          <li>
            <a className={styles.checkLink} href="#Ip">
              <span
                className={
                  !report?.report.ipResponse.isIpNow && report?.report.ipResponse.fio
                    ? styles.greenMark
                    : styles.redMark
                }
              />
              Индивидуальное предпринимательство
            </a>
          </li>

          <li>
            <a className={styles.checkLink} href="#disqualified">
              <span
                className={
                  report?.report.disqualifiedResponse.disqualifiedList.length === 0
                    ? styles.greenMark
                    : styles.redMark
                }
              />
              Ограничение занимать должность
            </a>
          </li>
          <li>
            <a className={styles.checkLink} href="#fsin">
              <span
                className={
                  report?.report.fsinResponse.info === null ? styles.greenMark : styles.redMark
                }
              />
              Проверка ФСИН
            </a>
          </li>
        </ul>

        <ul className={styles.reportItems}>
          <li className={styles.itemTitle} id="isp">
            <span
              className={
                report?.report.fsspResponse.count === 0 ? styles.greenMark : styles.redMark
              }
            />
            Исполнительное производство
          </li>

          {report?.report.fsspResponse.count !== 0 && (
            <li className={styles.infoDataRisk}>
              {report?.report.fsspResponse.count === null && (
                <p className={styles.reqItem}>
                  <b>Информация с сервера не получена</b>
                </p>
              )}
              {report?.report.fsspResponse.count !== 0 &&
                report?.report.fsspResponse.count !== null &&
                report?.report.fsspResponse.records.map((reqItem: IFsspItem) => {
                  return (
                    <ul
                      className={styles.reqItemBlock}
                      key={report.report.fsspResponse.records.indexOf(reqItem)}
                    >
                      <li className={styles.reqItem}>
                        <b>
                          <br />
                          Ответчик:
                        </b>{' '}
                        {reqItem.debtor_name}
                      </li>
                      <li className={styles.reqItem}>
                        <b>Судья: </b> {reqItem.officer_name}
                      </li>
                      <li className={styles.reqItem}>
                        <b>Дата суда:</b> {reqItem.process_date}
                      </li>
                      <li className={styles.reqItem}>
                        <b>Номер дела:</b> {reqItem.process_title}
                      </li>
                      {/* <li className={styles.reqItem}>
                        <b>Тип дела:</b> {reqItem.document_type}
                      </li> */}
                      <li className={styles.reqItem}>
                        <b> Исполнительный лист: </b>
                        {reqItem.recIspDoc}
                      </li>
                      <li className={styles.reqItem}>
                        <b> Суд: </b>
                        {reqItem.document_organization}
                      </li>

                      <li className={styles.reqItem}>
                        <b> Закончено: </b>
                        {reqItem.stopIP}
                      </li>
                      <li className={styles.reqItem}>
                        <b> Тип дела: </b>
                        {reqItem.subject}
                      </li>
                      <li className={styles.reqItem}>
                        <b> Сумма: </b>
                        {reqItem.sum}
                      </li>
                    </ul>
                  );
                })}
            </li>
          )}
          {report?.report.fsspResponse.count === 0 && (
            <li className={styles.reqItem}>Информации не найдено</li>
          )}

          <li className={styles.itemTitle} id="passport">
            <span
              className={
                report?.report.passportCheckResponse.result === 0
                  ? styles.greenMark
                  : styles.redMark
              }
            />
            Паспорт
          </li>

          <li className={styles.infoDataRisk}>
            <ul className={styles.reqItemBlock}>
              <li className={styles.reqItem}>
                <b>Действительность паспорта: </b>
                {report?.report.passportCheckResponse.result === 0 &&
                  'не значится среди недействительных'}
                {report?.report.passportCheckResponse.result === 1 &&
                  'НЕ ДЕЙСТВИТЕЛЕН (изъят, уничтожен)'}
                {report?.report.passportCheckResponse.result === 2 &&
                  'НЕ ДЕЙСТВИТЕЛЕН (технический брак)'}
                {report?.report.passportCheckResponse.result === 3 &&
                  'НЕ ДЕЙСТВИТЕЛЕН (заменен на новый)'}
                {report?.report.passportCheckResponse.result === 4 &&
                  'НЕ ДЕЙСТВИТЕЛЕН (утрата паспорта)'}
                {report?.report.passportCheckResponse.result === 5 &&
                  'НЕ ДЕЙСТВИТЕЛЕН (в связи со смертью владельца)'}
                {report?.report.passportCheckResponse.result === 6 &&
                  'НЕ ДЕЙСТВИТЕЛЕН (числится в розыске)'}
              </li>
            </ul>
          </li>

          <li className={styles.itemTitle} id="gibdd">
            <span
              className={
                report?.report.gibddResponse.decis !== null ||
                report?.report.gibddResponse.message ===
                  'Переданы данные о чужом водительском удостоверении. Дата рождения не совпала с данными из базы ГИБДД'
                  ? styles.redMark
                  : styles.greenMark
              }
            />
            Водительское удостоверение
          </li>

          <li className={styles.infoDataRisk}>
            {report?.report.gibddResponse.doc.division && (
              <ul className={styles.reqItemBlock}>
                <li className={styles.reqItem}>
                  <b>Отделение выдачи прав:</b> {report?.report.gibddResponse.doc.division}
                </li>
                <li className={styles.reqItem}>
                  <b>Дата выдачи прав:</b> {report?.report.gibddResponse.doc.date}
                </li>
                <li className={styles.reqItem}>
                  <b>Стаж:</b> {report?.report.gibddResponse.doc.stag}
                </li>
                <li className={styles.reqItem}>
                  <b>Категория водительских прав:</b> {report?.report.gibddResponse.doc.cat}
                </li>
                <li className={styles.reqItem}>
                  <b>Действительны до:</b> {report?.report.gibddResponse.doc.srok}
                </li>
                <li className={styles.reqItem}>
                  <b>Выдано в отделении:</b> {report?.report.gibddResponse.doc.divid}
                </li>

                {report?.report.gibddResponse.decis !== null &&
                  report?.report.gibddResponse.decis.map((reqItem: IDecisItem) => {
                    return (
                      <ul
                        className={styles.reqItemBlock}
                        key={report?.report.gibddResponse.decis.indexOf(reqItem)}
                      >
                        <li className={styles.reqItem}>{reqItem.comment}</li>
                        <li className={styles.reqItem}>
                          <b>Дата постановления:</b> {reqItem.date}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Номер постановления: </b>
                          {reqItem.fis_id}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Населенный пункт: </b>
                          {reqItem.bplace}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Место рождения: </b>
                          {reqItem.reg_name}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Состояние: </b>
                          {reqItem.state}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Срок лишения: </b>
                          {reqItem.srok}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Код </b>
                          {reqItem.reg_code}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Состояние исполнения постановления </b>
                          {reqItem.stateinfo}
                        </li>
                      </ul>
                    );
                  })}
              </ul>
            )}
            {report?.report.gibddResponse.message && (
              <li className={styles.reqItem}>{report.report.gibddResponse.message}</li>
            )}
          </li>

          <li className={styles.itemTitle} id="terrorist">
            <span
              className={
                !report?.report.rosFinMonResponse.found ? styles.greenMark : styles.redMark
              }
            />
            Террористы и экстремисты
          </li>

          {report?.report.rosFinMonResponse.found && (
            <li className={styles.infoDataRisk}>
              <ul className={styles.reqItemBlock}>
                <li className={styles.reqItem}>Найден в базе террористов и экстремистов</li>
                {report?.report.rosFinMonResponse.count !== 0 &&
                  report?.report.rosFinMonResponse.result.map((reqItem: IRosFinMonItem) => {
                    return (
                      <ul
                        className={styles.reqItemBlock}
                        key={report?.report.rosFinMonResponse.result.indexOf(reqItem)}
                      >
                        <li className={styles.reqItem}>
                          <b>Номер:</b> {reqItem.id}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Тип: </b>
                          {reqItem.type}
                        </li>
                        <li className={styles.reqItem}>
                          <b>ФИО: </b>
                          {reqItem.name}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Дата рождения: </b>
                          {reqItem.birth}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Место рождения: </b>
                          {reqItem.place}
                        </li>
                      </ul>
                    );
                  })}
              </ul>
            </li>
          )}
          {!report?.report.rosFinMonResponse.found && (
            <li className={styles.reqItem}>В базе террористов и экстремистов не найден</li>
          )}

          <li className={styles.itemTitle} id="bankrupt">
            <span
              className={
                !report?.report.bankruptResponse.totalCount ? styles.greenMark : styles.redMark
              }
            />
            Сведения о банкротстве
          </li>

          {report?.report.bankruptResponse.totalCount && (
            <li className={styles.infoDataRisk}>
              <ul className={styles.reqItemBlock}>
                {report?.report.bankruptResponse.rez !== null &&
                  report?.report.bankruptResponse.rez.length !== 0 &&
                  report?.report.bankruptResponse.rez.map((reqItem: IBankruptResponseItem) => {
                    return (
                      <ul
                        className={styles.reqItemBlock}
                        key={report.report.bankruptResponse.rez.indexOf(reqItem)}
                      >
                        <li className={styles.reqItem}>
                          <b>{reqItem.fio.title}:</b> {reqItem.fio.value}
                        </li>
                        <li className={styles.reqItem}>
                          <b>{reqItem.inn.title}:</b> {reqItem.inn.value}
                        </li>
                        <li className={styles.reqItem}>
                          <b>{reqItem.snils.title}:</b> {reqItem.snils.value}
                        </li>
                        <li className={styles.reqItem}>
                          <b>{reqItem.category.title}:</b> {reqItem.category.value}
                        </li>
                        <li className={styles.reqItem}>
                          <b>{reqItem.region.title}:</b> {reqItem.region.value}
                        </li>
                        <li className={styles.reqItem}>
                          <b>{reqItem.arbitrManagerFio.title}:</b> {reqItem.arbitrManagerFio.value}
                        </li>
                        <li className={styles.reqItem}>
                          <b>{reqItem.address.title}:</b> {reqItem.address.value}
                        </li>
                        <li className={styles.reqItem}>
                          <b>{reqItem.lastLegalCasenNumber.title}:</b>{' '}
                          {reqItem.lastLegalCasenNumber.value}
                        </li>
                        <li className={styles.reqItem}>
                          <b>{reqItem.status.title}:</b> {reqItem.status.value}
                        </li>
                        <li className={styles.reqItem}>
                          <b>{reqItem.description.title}:</b> {reqItem.description.value}
                        </li>
                        <li className={styles.reqItem}>
                          <b>{reqItem.updateDate.title}:</b> {reqItem.updateDate.value}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Статус дела:</b> {reqItem.isActive.value && 'в производстве'}
                          {!reqItem.isActive.value && 'завершено'}
                        </li>
                      </ul>
                    );
                  })}
              </ul>
            </li>
          )}
          {!report?.report.bankruptResponse.totalCount && (
            <li className={styles.reqItem}>Информации о банкротстве не найдено</li>
          )}

          <li className={styles.itemTitle} id="samoz">
            <span
              className={
                report?.report.selfEmplResponse.inn !== null ? styles.greenMark : styles.redMark
              }
            />
            Самозанятость
          </li>
          <li className={styles.infoDataRisk}>
            <p className={styles.reqItem}>
              Владелец ИНН &nbsp;
              {report?.report.selfEmplResponse.message}
            </p>
          </li>

          <li className={styles.itemTitle} id="Ip">
            <span
              className={
                !report?.report.ipResponse.isIpNow && report?.report.ipResponse.fio
                  ? styles.greenMark
                  : styles.redMark
              }
            />
            Индивидуальное предпринимательство
          </li>
          <ul className={styles.infoData}>
            <li className={styles.reqItem}>
              {!report?.report.ipResponse.isIpNow &&
                'Информации по индивидуальному предпринимательству не найдена'}
            </li>
            <li className={styles.reqItem}>
              {report?.report.ipResponse.isIpNow &&
                report?.report.ipResponse.ipInfo.map((reqItem: string) => {
                  return (
                    <ul
                      className={styles.reqItemBlock}
                      key={report?.report.ipResponse.ipInfo.indexOf(reqItem)}
                    >
                      <li className={styles.reqItem}>{reqItem}</li>
                    </ul>
                  );
                })}
            </li>
            <li className={styles.reqItem}>{report?.report.ipResponse.message}</li>
          </ul>

          <li className={styles.itemTitle} id="disqualified">
            <span
              className={
                report?.report.disqualifiedResponse.disqualifiedList.length === 0
                  ? styles.greenMark
                  : styles.redMark
              }
            />
            Запрет на занятие должности
          </li>
          <ul className={styles.infoData}>
            <li className={styles.reqItem}>
              {report?.report.disqualifiedResponse.disqualifiedList.length === 0 &&
                'Информация не найдена'}
              {report?.report.disqualifiedResponse.disqualifiedList.length !== 0 &&
                report?.report.disqualifiedResponse.disqualifiedList.map(
                  (reqItem: IDisqualifiedResponse) => {
                    return (
                      <ul
                        className={styles.reqItemBlock}
                        key={report?.report.disqualifiedResponse.disqualifiedList.indexOf(reqItem)}
                      >
                        <li className={styles.reqItem}>
                          <b>Название компании</b>&nbsp;
                          {reqItem.companyName}
                        </li>
                        <li className={styles.reqItem}>
                          <b>ИНН компании</b>&nbsp;
                          {reqItem.companyInn}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Занимаемая должность</b>&nbsp;
                          {reqItem.post}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Статья</b>&nbsp;
                          {reqItem.codexArticle}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Постановление вынесено</b>&nbsp;
                          {reqItem.protocolIssuer}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Ограничение от</b>&nbsp; {reqItem.startDate}
                        </li>
                        <li className={styles.reqItem}>
                          <b>Ограничение до</b>&nbsp; {reqItem.endDate}
                        </li>
                      </ul>
                    );
                  }
                )}
            </li>
            <li className={styles.itemTitle} id="fsin">
              <span
                className={
                  report?.report.fsinResponse.info === null ? styles.greenMark : styles.redMark
                }
              />
              Проверка ФСИН
            </li>
            <ul className={styles.infoData}>
              <li className={styles.reqItem}>
                {report?.report.fsinResponse.info === null && 'Информации не найдено'}
                {report?.report.fsinResponse.info !== null &&
                  report?.report.fsinResponse?.info.map((reqItem: IFsinResponse) => {
                    return (
                      <ul
                        className={styles.reqItemBlock}
                        key={report?.report.fsinResponse?.info?.indexOf(reqItem)}
                      >
                        <li className={styles.reqItem}>
                          <b>Округ</b>&nbsp;
                          {reqItem.federalInstitute} <p />
                          {reqItem.territoryInstitute}
                        </li>
                        <li className={styles.reqItem}>{reqItem.guidance}</li>
                        {reqItem.recordDate && (
                          <li className={styles.reqItem}>
                            <b>Дата</b>&nbsp;
                            {reqItem.recordDate}
                          </li>
                        )}
                        <li className={styles.reqItem}>
                          <b>Осужденный</b>&nbsp;
                          {reqItem.recordText}
                        </li>
                      </ul>
                    );
                  })}
              </li>
            </ul>
            <li className={styles.itemTitle} id="inn">
              <span
                className={report?.report.innResponse.inn ? styles.greenMark : styles.redMark}
              />
              ИНН
            </li>
            ИНН {report?.report.innResponse.inn}
            {report?.report.innResponse.message && (
              <li className={styles.reqItem}>{report?.report.innResponse.message}</li>
            )}
          </ul>
        </ul>
      </div>
      <div className={styles.btnBlock}>
        <Button type="button" btnText="Новый отчет" isButtonReport onClick={handleNewReport} />
        <Button
          type="submit"
          btnText="Скачать PDF"
          isBtnBlue
          isButtonReport
          onClick={handleDownloadPDFClick}
        />
      </div>
    </section>
  );
};

export default Report;
