import {
  CHECK_PERSON,
  CHECK_PERSON_FAILURE,
  CHECK_PERSON_RESET_FAILURE,
  CHECK_PERSON_SUCCESS,
  CHECK_PERSON_OFFLINE,
} from '../../utils/constants';
import { AppDispatch } from './types';
import { getPersonCheck } from '../../utils/Api';
import { ICheckPersonResponse } from '../../utils/check_person_interface';

interface IFetchCheckPersonProcessingAction {
  readonly type: typeof CHECK_PERSON;
}

interface IFetchCheckPersonSuccessAction extends ICheckPersonResponse {
  readonly type: typeof CHECK_PERSON_SUCCESS;
}

interface IFetchCheckPersonFailureAction {
  readonly type: typeof CHECK_PERSON_FAILURE;
}

interface ICheckPersonResetFailureActi {
  readonly type: typeof CHECK_PERSON_RESET_FAILURE;
}

interface ICheckPersonOffLine {
  readonly type: typeof CHECK_PERSON_OFFLINE;
}

export type UTCheckUser =
  | IFetchCheckPersonProcessingAction
  | IFetchCheckPersonSuccessAction
  | IFetchCheckPersonFailureAction
  | ICheckPersonResetFailureActi
  | ICheckPersonOffLine;

export const reportData = (res: any) => {
  return {
    id: res.id,
    personLastName: res.personLastName,
    personFirstName: res.personFirstName,
    personFatherName: res.personFatherName,
    personDateOfBirth: res.personDateOfBirth,
    personPassport: res.personPassport,
    personDrivingLicence: res.personDrivingLicence,
    personDateOfLicence: res.personDateOfLicence,
    report: {
      fsspResponse: {
        status: res.report.fsspResponse.status,
        message: res.report.fsspResponse.message,
        countAll: res.report.fsspResponse.countAll,
        pagesAll: res.report.fsspResponse.pagesAll,
        count: res.report.fsspResponse.count,
        totalLoadedPage: res.report.fsspResponse.totalLoadedPage,
        onlyActual: res.report.fsspResponse.onlyActual,
        records: res.report.fsspResponse.records,
      },
      innResponse: {
        status: res.report.innResponse.status,
        message: res.report.innResponse.message,
        found: res.report.innResponse.found,
        inn: res.report.innResponse.inn,
      },
      selfEmplResponse: {
        status: res.report.selfEmplResponse.status,
        message: res.report.selfEmplResponse.message,
        found: res.report.selfEmplResponse.found,
        inn: res.report.selfEmplResponse.inn,
        date: res.report.selfEmplResponse.date,
        npd: res.report.selfEmplResponse.npd,
      },
      passportCheckResponse: {
        status: res.report.passportCheckResponse.status,

        result: res.report.passportCheckResponse.result,
        info: res.report.passportCheckResponse.info,
      },
      gibddResponse: {
        status: res.report.gibddResponse.status,
        message: res.report.gibddResponse.message,
        doc: {
          division: res.report.gibddResponse.doc?.division,
          date: res.report.gibddResponse.doc?.date,
          stag: res.report.gibddResponse.doc?.stag,
          num: res.report.gibddResponse.doc?.num,
          cat: res.report.gibddResponse.doc?.cat,
          type: res.report.gibddResponse.doc?.type,
          srok: res.report.gibddResponse.doc?.srok,
          divid: res.report.gibddResponse.doc?.divid,
          bdate: res.report.gibddResponse.doc?.bdate,
        },
        decis: res.report.gibddResponse.decis,
        found: res.report.gibddResponse.found,
        cache: res.report.gibddResponse.cache,
      },
      rosFinMonResponse: {
        status: res.report.rosFinMonResponse.status,
        message: res.report.rosFinMonResponse.message,
        found: res.report.rosFinMonResponse.found,
        count: res.report.rosFinMonResponse.count,
        result: res.report.rosFinMonResponse.result,
      },
      bankruptResponse: {
        status: res.report.bankruptResponse.status,
        message: res.report.bankruptResponse.message,
        totalCount: res.report.bankruptResponse.totalCount,
        rez: res.report.bankruptResponse.rez,
        num: res.report.bankruptResponse.num,
      },
      disqualifiedResponse: {
        status: res.report.disqualifiedResponse.status,
        disqualifiedList: res.report.disqualifiedResponse.disqualifiedList,
        message: res.report.disqualifiedResponse.message,
      },
      fsinResponse: {
        info: res.report.fsinResponse.info,
        message: res.report.fsinResponse.message,
        status: res.report.fsinResponse.status,
      },
      ipResponse: {
        fio: res.report.ipResponse.fio,
        status: res.report.ipResponse.status,
        isIpNow: res.report.ipResponse.isIpNow,
        ipInfo: res.report.ipResponse.ipInfo,
        message: res.report.ipResponse.message,
      },
    },
    reportComments: res.report.reportComments,
    isSuccess: res.report.isSuccess,
    reportDateTime: res.report.reportDateTime,
  };
};

export const checkPerson = (
  lastName: string,
  firstName: string,
  fatherName: string,
  passport: string,
  drivingLicence: string,
  dateOfBirth: string,
  dateOfLicence: string,
  userId: number
) => {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: CHECK_PERSON,
    });

    return getPersonCheck(
      lastName,
      firstName,
      fatherName,
      passport,
      drivingLicence,
      dateOfBirth,
      dateOfLicence,
      userId
    )
      .then((res) => {
        // @ts-ignore
        dispatch({
          type: CHECK_PERSON_SUCCESS,
          ...reportData(res),
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-restricted-syntax
        console.log(`err 138 ${err}`);
        dispatch({
          type: CHECK_PERSON_FAILURE,
          failureMessage: err,
        });
        console.error(err);
      });
  };
};

export const checkPersonOffLine = (res: ICheckPersonResponse[]) => ({
  type: CHECK_PERSON_OFFLINE,
  reportHistory: res,
});

export const checkPersonResetFailure = (): ICheckPersonResetFailureActi => ({
  type: CHECK_PERSON_RESET_FAILURE,
});
