import React, { FC } from 'react';
import { useSelector } from '../../services/hooks/reduxHooks';
import { RootState } from '../../services/actions/types';
import styles from './EditProfile.module.css';
import avatar from '../../images/avatar.svg';
import EditPassword from '../../components/EditPassword/EditPassword'
import EditUserInfo from '../../components/EditUserInfo/EditUserInfo'


const EditProfile: FC = (): React.ReactElement => {
  const currentUser: any = useSelector((state: RootState) => state.currentUser);

  return (
    <section className={styles.editPassword}>
      <div className={styles.userContainer}>
        <img src={avatar} alt="Аватар пользователя" className={styles.avatar} />
        <div className={styles.userSection}>
          <div className={styles.userInfo}>
            <p className={styles.userFirstName} id="firstName">
              {currentUser.firstName}
            </p>
            <p className={styles.userLastName} id="lastName">
              {currentUser.lastName}
            </p>
            <span className={styles.slash}>/</span>
            <p className={styles.infoPage}>Пароль</p>
          </div>
          <p className={styles.infoAction}>Смена пароля</p>
        </div>
      </div>
      <div className={styles.controlChapter}>
        <div className={styles.actionChapter}>
          <p className={styles.actionLine}>Редактировать данные</p>
          <p className={styles.actionLine}>Пароль</p>
          <p className={styles.actionLine}>История платежей</p>
        </div>
        <EditUserInfo />
        <EditPassword />
      </div>
    </section>
  );
};

export default EditProfile;
