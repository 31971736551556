import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from '../../services/hooks/reduxHooks';
import logo from '../../images/logo.svg';
import ruble from '../../images/ruble.svg';

import avatarProf from '../../images/avatarProf.svg';
import { RootState } from '../../services/actions/types';
import styles from './Header.module.css';
import stylesLink from '../../pages/MainPage/MainPage.module.css';
import Button from '../Button/Button';

interface HeaderProps {
  isSignUpPopupOpened?: () => void;
  isSignInPopupOpened?: () => void;
  isUserProfilePopupOpened?: () => void;
}

const Header: FC<HeaderProps> = ({
  isSignUpPopupOpened = () => {},
  isSignInPopupOpened = () => {},
  isUserProfilePopupOpened = () => {},
}): React.ReactElement => {
  // TODO
  const currentUser: any = useSelector((state: RootState) => state.currentUser);

  const navigate = useNavigate();
  const handleReportHistory = () => {
    navigate('/report-history');
  };

  const openSignUpPopup = () => {
    isSignUpPopupOpened();
  };

  const openSignInPopup = () => {
    isSignInPopupOpened();
  };

  const openUserProfilePopup = () => {
    isUserProfilePopupOpened();
  };

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Link to="/" className={styles.linkToMain}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" className={styles.logoImg} />
          </div>
          <p className={`${styles.monitoring} ${stylesLink.link}`}>Физрадар</p>
        </Link>
        {currentUser.isLoggedIn === false ? (
          <nav className={styles.nav}>
            <ul className={styles.auth}>
              <li className={`${styles.authItem} ${stylesLink.link} ${styles.regBtn}`}>
                <Button
                  type="button"
                  btnText="Регистрация"
                  isButtonHeader
                  isBtnBlue={false}
                  isBtnDisabled={false}
                  onClick={openSignUpPopup}
                  isButtonLarge={false}
                />
              </li>
              <li className={`${styles.authItem} ${stylesLink.link}`}>
                <Button
                  type="button"
                  btnText="Вход"
                  isButtonHeader
                  isBtnBlue={false}
                  isBtnDisabled={false}
                  onClick={openSignInPopup}
                  isButtonLarge={false}
                />
              </li>
            </ul>
          </nav>
        ) : (
          <div className={`${styles.userContainer}`}>
            <p className={styles.balance}>
              Баланс <span className={styles.sum}>{currentUser.balance}</span>{' '}
              <img src={ruble} alt="logo" className={styles.ruble} />
            </p>
            <button className={styles.btnReportHistory} type="button" onClick={handleReportHistory}>
              Мои отчёты
            </button>
            <button onClick={openUserProfilePopup} type="button" className={styles.avatarBtn}>
              <img src={avatarProf} alt="user-logo" className={styles.avatarProf} />
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

Header.defaultProps = {
  isSignUpPopupOpened: () => {},
  isSignInPopupOpened: () => {},
  isUserProfilePopupOpened: () => {},
};

export default Header;
