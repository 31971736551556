import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { PATTERN_PASSWORD } from '../../utils/constants';
import styles from './EditPassword.module.css';

const EditPassword: FC = (): React.ReactElement => {
  const {
    register,
    formState: { errors },
    // handleSubmit,
    // watch,
    // reset,
  } = useForm({
    mode: 'onChange',
  });

  const passwordError = errors?.password?.message?.toString();
  
  return (
    <div className={styles.infoForm}>
      <form className={styles.form}>
        <div className={styles.formContainer}>
          <label className={styles.label} htmlFor="text">
            Старый пароль
          </label>
          <input className={styles.input} type="text" />
          {/* <span className={styles.spanError}>Сообщение</span> */}
          <label className={styles.label} htmlFor="text">
            Новый пароль
          </label>
          <input
            className={styles.input}
            type="text"
            required
            {...register('password', {
              // required: 'Поле обязательно к заполнению',
              maxLength: {
                value: 40,
                message: 'Пароль не более 40 символов',
              },
              pattern: {
                value: PATTERN_PASSWORD,
                message:
                  'Минимум 8 символов, одна цифра, спецсимвол, одна буква в верхнем регистре и одна в нижнем',
              },
              minLength: {
                value: 8,
                message:
                  'Минимум 8 символов, одна цифра, спецсимвол, одна буква в верхнем регистре и одна в нижнем',
              },
            })}
          />
          <span className={styles.spanError}>{passwordError}</span>
        </div>
        <div className={styles.btnContainer}>
          <button type="submit" className={styles.btn}>
            Сохранить
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPassword;
