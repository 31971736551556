import React, { FC } from 'react';

import { useSelector } from '../../services/hooks/reduxHooks';
import { IReport } from '../../components/Report/reportInterface';

import Report from '../../components/Report/Report';

const ReportPage: FC<IReport> = ({ personRequestInfo }): React.ReactElement => {
  const personReport: any = useSelector((store) => store.checkPerson);

  return <Report personRequestInfo={personRequestInfo} report={personReport} />;
};

export default ReportPage;
