import React, { FC, Dispatch, SetStateAction } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { RootState } from '../../services/actions/types';
import { useSelector } from '../../services/hooks/reduxHooks';
import styles from './userProfilePopup.module.css';
import avatar from '../../images/avatar.svg';
import closeButtonImage from '../../images/close-button.svg';
import { signOut } from '../../utils/Api';

interface UserProfilePopupProps {
  isPopupOpened: boolean;
  onClose: () => void;
  isLoggedIn: Function;
  isInfoPopupOpened: Function;
  infoText: Dispatch<SetStateAction<any>>;
}

const UserProfilePopup: FC<UserProfilePopupProps> = ({
  isPopupOpened,
  onClose,
  isLoggedIn,
  isInfoPopupOpened,
  infoText,
}): React.ReactElement => {
  const navigate = useNavigate();

  const currentUser: any = useSelector((state: RootState) => state.currentUser);

  document.addEventListener('keydown', closeByEscape);
  document.addEventListener('mouseup', handMouseClosePopup);

  function closeByEscape(evt: { key: string }) {
    if (evt.key === 'Escape') {
      onClose();
    }
  }

  function handMouseClosePopup(event: MouseEvent) {
    if (event.target === document.querySelector('.userProfilePopup_userPopupOpened__D2YrV')) {
      onClose();
    }
  }

  const handleSignOut = () => {
    signOut()
      .then(() => {
        isLoggedIn(false);
        navigate('/', { replace: true });
        onClose();
      })
      .catch((err) => {
        isInfoPopupOpened(true);
        infoText(`Ошибка: ${err.message} ${err.status}`);
      });
  };

  return (
    <section className={`${styles.userPopup} ${isPopupOpened ? styles.userPopupOpened : ''}`}>
      <div className={styles.userContainer}>
        <button className={styles.closeButton} type="button" onClick={onClose}>
          {/* {} */}
          <img className={styles.closeButtonImg} src={closeButtonImage} alt="Закрыть" />
        </button>
        <img src={avatar} alt="Аватар пользователя" className={styles.avatar} />
        <p className={styles.userFirstName} id="firstName">
          {currentUser.firstName}
        </p>
        <p className={styles.userLastName} id="lastName">
          {currentUser.lastName}
        </p>
        <p className={styles.userEmail} id="userEmail">
          {currentUser.email}
        </p>
        <div className={styles.infoRequest}>
          <p className={styles.infoText}>Осталось отчетов:</p>
          <p className={styles.numberRequest}>{currentUser.countFreeReport}</p>
        </div>
        <div className={styles.userInfo}>
          <p className={styles.infoText}>Редактировать данные</p>
          <Link to="/edit-password" className={styles.linkToMain}>
            <p className={styles.infoText}>Пароль</p>
          </Link>
          <p className={styles.infoText}>История платежей</p>
        </div>
        <button type="submit" onClick={handleSignOut} className={styles.exitBtn}>
          Выйти
        </button>
      </div>
    </section>
  );
};

export default UserProfilePopup;
