import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ReportHistory.module.css';
import download from '../../images/download.svg';
import eyeopen from '../../images/eyeopen.svg';
import message from '../../images/message.svg';
import done from '../../images/done.svg';
import arrow from '../../images/arrow.svg';
import arrowLeft from '../../images/arrowLeft.svg';
import Button from '../../components/Button/Button';

const ReportHistory: FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const handleNewReport = () => {
    navigate('/registereduser');
  };

  return (
    <section className={styles.section}>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>История отчетов</h1>
        <Button type="button" btnText="Новый отчет" isButtonReport onClick={handleNewReport} />
      </div>
      <div className={styles.reportHistoryContainer}>
        <form className={styles.form}>
          <input
            className={styles.inputHistory}
            type="search"
            id="search"
            placeholder="        Поиск по фамилии"
          />
        </form>
        <ul className={styles.headerTable}>
          <li className={styles.columnName}>Дата</li>
          <li className={styles.columnName}>ФИО</li>
          <li className={styles.columnName}>Стоимость</li>
          <li className={styles.columnName}>Статус</li>
        </ul>
        <ul className={styles.infoTable}>
          <li className={styles.infoCheckPerson}>
            13.04.2024 <span className={styles.del}>|</span> 19:57
          </li>
          <li className={styles.infoCheckPerson}>Багаев Виталий Сергеевич</li>
          <li className={styles.infoCheckPerson}>100,00 ₽</li>
          <li className={styles.infoCheckPerson}>
            <img src={done} alt="Добавить комментарий к отчету" className={styles.iconDone} />
            Выполнено
          </li>
          <li className={styles.icon}>
            <img src={download} alt="Скачать отчет" className={styles.iconImg} />
          </li>
          <li className={styles.icon}>
            <img src={eyeopen} alt="Посмотреть отчет" className={styles.iconImg} />
          </li>
          <li className={styles.icon}>
            <img src={message} alt="Добавить комментарий к отчету" className={styles.iconImg} />
          </li>
        </ul>
      </div>
      <div className={styles.leafThrough}>
        <button className={styles.btnGo} type="button">
          <img src={arrowLeft} alt="Стрелка листать влево" className={styles.iconBtn} />
        </button>
        <p className={styles.pageNumber}>1</p>
        {/* <p className={`${styles.pageNumber} ${styles.notActive}`}>2</p> */}
        <button className={styles.btnGo} type="button">
          <img src={arrow} alt="Стрелка листать вправо" className={styles.iconBtn} />
        </button>
      </div>
    </section>
  );
};

export default ReportHistory;
