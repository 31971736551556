import {
  CHECK_PERSON,
  CHECK_PERSON_FAILURE,
  CHECK_PERSON_OFFLINE,
  CHECK_PERSON_SUCCESS,
  // CHECK_PERSON_RESET,
} from '../../utils/constants';
import { ICheckPersonResponse } from '../../utils/check_person_interface';
import { TApplicationActions } from '../actions/types';
import { reportData } from '../actions/checkPersonActions';

export interface ICheckPersonReducer extends ICheckPersonResponse {
  checkPersonProcessing: boolean;
  checkPersonFailure: boolean;
}

const initialState: ICheckPersonReducer = {
  checkPersonProcessing: false,
  checkPersonFailure: false,
  id: null,
  personLastName: '',
  personFirstName: '',
  personFatherName: '',
  personDateOfBirth: '',
  personPassport: '',
  personDrivingLicence: '',
  personDateOfLicence: '',
  report: {
    fsspResponse: {
      status: null,
      message: '',
      countAll: '',
      pagesAll: '',
      count: null,
      totalLoadedPage: null,
      onlyActual: null,
      records: [
        {
          recIspDoc: '',
          stopIP: '',
          subject: '',
          sum: '',
          debtor_name: '',
          debtor_address: '',
          debtor_dob: '',
          process_title: '',
          process_date: '',
          document_organization: '',
          document_type: '',
          officer_name: '',
          officer_phones: '',
        },
      ],
    },
    innResponse: {
      status: null,
      message: '',
      found: false,
      inn: '',
    },
    selfEmplResponse: {
      status: null,
      message: '',
      found: false,
      inn: '',
      date: '',
      npd: null,
    },
    passportCheckResponse: {
      status: null,
      message: '',
      result: null,
      info: '',
    },
    gibddResponse: {
      status: null,
      message: '',
      doc: {
        division: '',
        date: '',
        stag: '',
        num: '',
        cat: '',
        type: '',
        srok: '',
        divid: '',
        bdate: '',
      },
      decis: [],
      found: false,
      cache: '',
    },
    rosFinMonResponse: {
      status: null,
      message: '',
      found: false,
      count: null,
      result: '',
    },
    bankruptResponse: {
      status: null,
      message: '',
      totalCount: null,
      rez: null,
      num: null,
    },
    disqualifiedResponse: {
      status: null,
      disqualifiedList: [],
      message: '',
    },
    fsinResponse: {
      info: null,
      message: '',
      status: null,
    },
    ipResponse: {
      fio: null,
      status: null,
      isIpNow: false,
      ipInfo: [],
      message: '',
    },
  },
  reportComments: '',
  isSuccess: false,
  reportDateTime: '',
};

const checkPersonResponce = (state = initialState, action: TApplicationActions) => {
  switch (action.type) {
    case CHECK_PERSON: {
      return {
        ...state,
        checkPersonProcessing: true,
        checkPersonFailure: false,
      };
    }
    case CHECK_PERSON_SUCCESS: {
      return {
        ...state,
        checkPersonProcessing: false,
        ...reportData(action),
      };
    }
    case CHECK_PERSON_FAILURE: {
      return {
        ...state,
        checkPersonProcessing: false,
        checkPersonFailure: true,
      };
    }
    case CHECK_PERSON_OFFLINE: {
      return {
        ...state,
        checkPersonProcessing: false,
        checkPersonFailure: false,
        ...reportData(action),
      };
    }

    default: {
      return state;
    }
  }
};

export default checkPersonResponce;
