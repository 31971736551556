// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f6f6f6;
  font-family: 'Open sans', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  /* padding: 0 6.9vw; */
}

* {
  box-sizing: border-box;
  outline: 0;
}

.btnBlock {
  display: flex;
  margin: 20px 0 20px;
  column-gap: 20px;
}

@media screen and (max-width: 600px) {
  .app {
    padding: 0 1vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/App/App.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;EACzB,6CAA6C;EAC7C,mCAAmC;EACnC,kCAAkC;EAClC,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".app {\n  /* max-width: 1440px; */\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  min-height: 100vh;\n  background-color: #f6f6f6;\n  font-family: 'Open sans', 'Arial', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  margin: 0 auto;\n  /* padding: 0 6.9vw; */\n}\n\n* {\n  box-sizing: border-box;\n  outline: 0;\n}\n\n.btnBlock {\n  display: flex;\n  margin: 20px 0 20px;\n  column-gap: 20px;\n}\n\n@media screen and (max-width: 600px) {\n  .app {\n    padding: 0 1vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
