import {
  GET_USER_CREDENTIALS,
  GET_USER_CREDENTIALS_SUCCESS,
  GET_USER_CREDENTIALS_FAILURE,
  USER_LOGOUT,
  USER_RESET_FAILURE,
} from '../../utils/constants';
import {
  loginUser as loginUserApi,
  confirmEmail,
  authCheck,
  updateUserCredentials,
} from '../../utils/Api';
import { AppDispatch } from './types';

interface IFetchUserProcessingAction {
  readonly type: typeof GET_USER_CREDENTIALS;
}

interface IFetchUserSuccessAction {
  readonly type: typeof GET_USER_CREDENTIALS_SUCCESS;
  id: number | null;
  firstName: string;
  lastName: string;
  email: string;
  countFreeReport: number;
  balance: number;
  role: string;
  registered: string;
}

interface IFetchUserFailureAction {
  readonly type: typeof GET_USER_CREDENTIALS_FAILURE;
  failureMessage?: string;
}

interface ILogOutUserAction {
  readonly type: typeof USER_LOGOUT;
}

interface IUserResetFailureAction {
  readonly type: typeof USER_RESET_FAILURE;
}

export type UTUserActions =
  | IFetchUserProcessingAction
  | IFetchUserSuccessAction
  | IFetchUserFailureAction
  | ILogOutUserAction
  | IUserResetFailureAction;

const loginUser = (email: string, password: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: GET_USER_CREDENTIALS,
  });

  return loginUserApi(email, password)
    .then((res) => {
      dispatch({
        type: GET_USER_CREDENTIALS_SUCCESS,
        id: res.id,
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        countFreeReport: res.countFreeReport,
        balance: res.balance,
        role: res.role,
        registered: res.registered,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_USER_CREDENTIALS_FAILURE,
        failureMessage: err.message,
      });
      console.error(err);
    });
};

const confirmUserByEmail = (email: string, code: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: GET_USER_CREDENTIALS,
  });

  return confirmEmail(email, code)
    .then((res) => {
      dispatch({
        type: GET_USER_CREDENTIALS_SUCCESS,
        id: res.id,
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        countFreeReport: res.countFreeReport,
        balance: res.balance,
        role: res.role,
        registered: res.registered,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_USER_CREDENTIALS_FAILURE,
        failureMessage: err.message,
      });
      console.error(err);
    });
};

const updateUser = () => (dispatch: AppDispatch) => {
  dispatch({
    type: GET_USER_CREDENTIALS,
  });

  return authCheck()
    .then((res) => {
      dispatch({
        type: GET_USER_CREDENTIALS_SUCCESS,
        id: res.id,
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        countFreeReport: res.countFreeReport,
        balance: res.balance,
        role: res.role,
        registered: res.registered,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_USER_CREDENTIALS_FAILURE,
        failureMessage: err.message,
      });
      console.error(err);
    });
};

const updateUserData =
  (email: string, firstName: string, lastName: string, password: string | undefined) =>
  (dispatch: AppDispatch) => {
    dispatch({
      type: GET_USER_CREDENTIALS,
    });

    return updateUserCredentials(email, firstName, lastName, password)
      .then((res) => {
        dispatch({
          type: GET_USER_CREDENTIALS_SUCCESS,
          id: res.id,
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.email,
          countFreeReport: res.countFreeReport,
          balance: res.balance,
          role: res.role,
          registered: res.registered,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_USER_CREDENTIALS_FAILURE,
          failureMessage: err.message,
        });
        console.error(err);
      });
  };

const logOutUser = (): ILogOutUserAction => ({
  type: USER_LOGOUT,
});

const userResetFailure = (): IUserResetFailureAction => ({
  type: USER_RESET_FAILURE,
});

export { confirmUserByEmail, loginUser, logOutUser, updateUser, updateUserData, userResetFailure };
