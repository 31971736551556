import React, { FC } from 'react';
import styles from './MainPage.module.css';
import Landing from '../../components/Landing/Landing';
import About from '../../components/About/About';
import Sources from '../../components/Sources/Sources';
// import Services from '../../components/Services/Services';
import SimpleSlider from '../../components/Slider/Slider';

interface MainPageProps {
  isSignInPopupOpened: () => void;
}

const MainPage: FC<MainPageProps> = ({ isSignInPopupOpened }): React.ReactElement => {
  return (
    <main className={styles.main}>
      <Landing isSignInPopupOpened={isSignInPopupOpened} />
      <SimpleSlider />
      {/* <Services /> */}
      <About />
      <Sources />
    </main>
  );
};

export default MainPage;
